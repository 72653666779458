<page-atsugi-cook-application>
  <div class="c-content">
    <section>
      <ui-heading data-tag="h1" data-theme="cook">2024年度<br>厚木総合専門学校 調理学科<br>生徒募集要項</ui-heading>
      <table class="c-table o-space">
        <tr>
          <th>出願基準</th>
          <td>中学校卒業見込み、及び卒業以上または、これと同等以上の学歴を有する者</td>
        </tr>
        <tr>
          <th>募集人員</th>
          <td class="o-cancel-first-child-gap">
            <p>100名</p>
          </td>
        </tr>
        <tr>
          <th>修業年限</th>
          <td>1年</td>
        </tr>
        <tr>
          <th>卒業時資格</th>
          <td class="o-fit">
            <ul class="c-list c-list--theme-cook">
              <li>調理師免許（厚生労働大臣指定校の為、国家試験は免除）</li>
              <li>食品衛生責任者</li>
              <li>専門調理師（6年後、学科免除）</li>
              <li>食育インストラクター（希望者のみ）</li>
            </ul>
          </td>
        </tr>
        <tr>
          <th>入学期</th>
          <td>4月</td>
        </tr>
      </table>
      <div class="o-spacer o-spacer--large"></div>
      <div class="c-sticky-block">
        <ui-tabs data-tabs="{tabs}" data-current="{state.currentTab}" data-theme="cook"
          data-tab-click-handler="{clickTabHandler}">
        </ui-tabs>
      </div>
      <intersection-area data-disable="{ state.disableIntersection }" data-id="expenses"
        data-intersection-handler="{intersectionHandler}">
        <section class="o-layout o-layout--column" id="expenses">
          <div>
            <ui-heading data-tag="h1" data-theme="cook">学費</ui-heading>
          </div>
          <div>
            <div class="c-items-scroll">
              <table class="c-table c-table--keep-layout" style="min-width: 500px;">
                <tr>
                  <th>入学金</th>
                  <th>教育充実費</th>
                  <th>維持費</th>
                  <th>授業料<br><small>（1ヶ月）</small></th>
                  <th>調理実習費<br><small>（1ヶ月）</small></th>
                  <th><span class="c-label c-label--theme-cook">合計</span></th>
                </tr>
                <tr>
                  <td>150,000円</td>
                  <td>120,000円</td>
                  <td>60,000円</td>
                  <td>37,000円</td>
                  <td>10,000円</td>
                  <td>894,000円</td>
                </tr>
              </table>
              <ul class="c-list c-list--theme-cook">
                <li>学費は合格決定後、指定日までの納入になります。納入されない場合は入学を取り消すことがあります。</li>
                <li>授業料、実習費は6ヶ月分を入学時と9月の2回に分納となります。（一括払いも可能です。）</li>
                <li>その他に、教材・白衣・包丁・行事費などで、約22～23万円が必要です。※物価高騰により変動有</li>
                <li>一旦提出された書類及び入学検定料、入学金は返還しません。</li>
                <li>分割ローンの方法もありますので、納入期日の前にお問合せください。（個別相談も可能です）</li>
                <li>アルバイトを希望する場合は、飲食店を紹介することが可能です。</li>
              </ul>
            </div>
          </div>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-cook">親族入学支援制度</h1>
            <p>入学希望者の親族が本校卒業生、もしくは在学生である場合、入学金を100%または50%免除する制度です。</p>
            <p>（同法人からの入学者、同時入学も免除の対象になります。）</p>
            <section>
              <h2 class="c-heading-5 c-heading-5--theme-cook">対象入学</h2>
              <p>全入学（出願）方法</p>
            </section>
            <section>
              <h2 class="c-heading-5 c-heading-5--theme-cook">支援資格</h2>
              <section>
                <h3 class="c-heading-6 c-heading-6--theme-cook">入学金100%免除</h3>
                <ul class="c-list c-list--theme-cook">
                  <li>同法人からの入学者及び本校に在籍または同時入学、本校の卒業生が2親等以内にいる場合</li>
                </ul>
              </section>
              <section>
                <h3 class="c-heading-6 c-heading-6--theme-cook">入学金50%免除</h3>
                <ul class="c-list c-list--theme-cook">
                  <li>同法人に在籍または同時入学、同法人の卒業生が2親等以内にいる場合</li>
                </ul>
              </section>
              <ul class="c-note-list">
                <li>1親等：父母、子</li>
                <li>2親等：兄弟姉妹、祖父母、孫</li>
                <li>3親等：伯父・伯母、甥・姪、曽祖父母、曽孫</li>
              </ul>
            </section>
            <section>
              <h2 class="c-heading-5 c-heading-5--theme-cook">申請手続き</h2>
              <p>上記「支援資格」に該当する方は、入学願書の所定欄に氏名（旧姓）、続柄、学校名を記入してください。</p>
            </section>
            <section>
              <h2 class="c-heading-5 c-heading-5--theme-cook">親族入学支援制度の注意事項</h2>
              <p>中途退学した場合、退学時に免除額の全額を返済していただきます。<br>該当者には合格証と一緒に誓約書を同封。</p>
            </section>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-cook">就学支援金・学費補助金について</h1>
            <p>
              高等学校等就学支援金（国の制度）は、私立高校等に在学する生徒が、家庭の状況にかかわらず安心して勉学に打ち込めるよう、生徒の授業料に充てる費用として支援金を支給（学校設置者が代理受領）し、家庭の教育費負担を図るものです。
              <br>（2022年度実績では、月額9,900円、年額118,800円支給。本校の場合：授業料負担額（1ヶ月）は37,000円-9,900円=<b
                class="c-strong c-strong--theme-cook">27,100円</b>となります。）
            </p>
            <p>
              学費補助金（神奈川県の制度）は、県内の私立高校等に在学する生徒の保護者等の経済的負担を軽減するため、入学金・授業料を補助する制度です。また、保護者の所得が規定の金額以下の場合、申請により学費の一部が入学後補助されます。
            </p>
            <small>
              <ul class="c-note-list">
                <li>生徒・保護者ともに県内在住、かつ県内設置の学校に在学する生徒が対象です。</li>
              </ul>
            </small>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-cook">各種教育ローンや給付制度の利用について</h1>
            <section>
              <h2 class="c-heading-5 c-heading-5--theme-cook">国の教育ローン（日本政府金融公庫）</h2>
              <p>融資金額300万円以内、返済期間15年</p>
            </section>
            <section>
              <h2 class="c-heading-5 c-heading-5--theme-cook">一般教育訓練給付</h2>
              <p>1年以上働いている方・ハローワークより10万円支給されます。（対象者・申請者のみ）</p>
            </section>
            <section>
              <h2 class="c-heading-5 c-heading-5--theme-cook">学費補助制度</h2>
              <p>国の支援金、県の補助金が支給されます。</p>
              <ul class="c-note-list">
                <li>ただし高等学校を卒業した方や在学期間36月を超える場合は対象外、一定の条件が必要です。</li>
              </ul>
            </section>
            <section>
              <h2 class="c-heading-5 c-heading-5--theme-cook">生活福祉資金（教育支援資金）</h2>
            </section>
            <section>
              <h1 class="c-heading-5 c-heading-5--theme-cook">公的補助事業</h1>
              <p>一般教育訓練給付指定講座（勤続1年以上の方）</p>
            </section>
            <section>
              <h1 class="c-heading-5 c-heading-5--theme-cook">公共職業訓練受託事業</h1>
              <p>
                専門人材育成コース（ハローワーク受付）令和5年度受託決定
                <br>詳しくは、本校事務局までお問い合わせください。一定の条件が必要です。
              </p>
            </section>
            <hr class="c-separator">
            <small>
              <ul class="c-note-list">
                <li>1年の学校のため、奨学金制度より国の教育ローンをおすすめします。</li>
                <li>詳しくは、本校事務局までお問合せください。</li>
              </ul>
            </small>
          </section>
        </section>
      </intersection-area>
      <intersection-area data-disable="{ state.disableIntersection }" data-id="adult-from-17"
        data-intersection-handler="{intersectionHandler}">
        <section id="adult-from-17">
          <ui-heading data-tag="h1" data-theme="cook">出願手続および選考：<br><b>17歳〜大人の方</b></ui-heading>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-cook">募集期間</h1>
            <p>
              2023年10月2日(月)〜2024年3月22日(金)まで<br>
              9時00分〜16時30分まで（土曜日は14時30分、最終日は12時00分まで）
            </p>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-cook">出願書類</h1>
            <ol>
              <li>入学検定料 20,000円</li>
              <li>入学願書（本校指定）</li>
              <li>卒業証明書または卒業見込証明書（最終学歴）</li>
              <li>成績証明書（または調査書など）（最終学歴）</li>
              <li>写真3枚（上半身4cm×3cm 3ヶ月以内に撮影したもの）<br>3枚とも裏面に氏名を記入し、内2枚は願書・受験票に貼付、内1枚はそのまま提出</li>
            </ol>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-cook">選考日時</h1>
            <p>
              出願後10日以内に連絡をします。
              <br>（都合の悪い場合、変更は可能です）
            </p>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-cook">選考会場</h1>
            <p>厚木総合専門学校 3階</p>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-cook">選考方法</h1>
            <p>書類審査・面接（ペーパー試験はありません）</p>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-cook">合格発表</h1>
            <p>
              選考日より10日以内に結果を郵送いたします。
              <br>（支払い・今後の予定等同封）
            </p>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-cook">入学手続き</h1>
            <p>
              入学手続きは入学手続案内書で詳細を通知しますが、入学手続締切日までに学費を納付することで入学手続きを完了します。入学手続締切日までに納付できない場合にはお問い合わせください。
              <br>1年分の一括払いや、事情のある方については、お問い合わせください。
              <br>3月31日までに入学辞退の申し出があった場合、入学金及び検定料以外の校納金を返還いたします。
            </p>
          </section>
        </section>
      </intersection-area>
      <intersection-area data-disable="{ state.disableIntersection }" data-id="graduate"
        data-intersection-handler="{intersectionHandler}">
        <section id="graduate">
          <ui-heading data-tag="h1" data-theme="cook">出願手続および選考：<br><b>中学卒業見込みの方</b></ui-heading>
          <p>厚木中央高等学校との同時入学が可能です。</p>
          <p class="o-items-center">
            <ui-button data-tag="a" data-href="/chuo/application" data-class="theme-cook">厚木中央高等学校 募集要項</ui-button>
          </p>
        </section>
      </intersection-area>
      <intersection-area data-disable="{ state.disableIntersection }" data-id="recommended"
        data-intersection-handler="{intersectionHandler}">
        <section id="recommended">
          <ui-heading data-tag="h1" data-theme="cook">出願手続および選考：<br><b>推薦入学志願者</b></ui-heading>
          <p>中学卒業見込みの方・高校同時入学者は高校の願書も提出</p>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-cook">募集期間</h1>
            <p>
              2023年10月2日(月)～2023年12月14日(木)まで
              <br>9時00分～16時30分まで（土曜日は14時30分、最終日は12時00分まで）
            </p>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-cook">出願書類</h1>
            <ul>
              <li>入学検定料 20,000円</li>
              <li>出身学校長の推薦書（各中学校書式）</li>
              <li>入学願書（本校所定）</li>
              <li>調査書（公立高等学校向けの用紙に、出身校にて記載したもの）</li>
              <li>写真3枚（3枚とも裏面に氏名を記入し、内2枚は願書・受験票に貼付、内1枚はそのまま提出）</li>
            </ul>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-cook">選考日時</h1>
            <p>2023年12月15日(金) 9時00分より</p>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-cook">選考会場</h1>
            <p>厚木総合専門学校 3階</p>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-cook">選考方法</h1>
            <p>書類審査・個別面接</p>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-cook">合格発表</h1>
            <p>各中学校に通知します。</p>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-cook">入学手続き</h1>
            <p>入学手続は、入学手続案内書で詳細を通知しますが、2024年1月9日(火)までに学費を納付することで入学手続を完了します。入学手続締め切りまでに納付できない場合はお問い合わせください。</p>
            <p>
              1年分の一括払いや、事情のある方については、お問い合わせください。
              <br>3月31日までに入学辞退の申し出があった場合、入学金及び検定料以外の校納金を返還いたします。
          </section>
        </section>
      </intersection-area>
      <intersection-area data-disable="{ state.disableIntersection }" data-id="general"
        data-intersection-handler="{intersectionHandler}">
        <section id="general">
          <ui-heading data-tag="h1" data-theme="cook">出願手続および選考：<br><b>一般入学志願者</b></ui-heading>
          <p>中学卒業見込みの方・高校同時入学者は高校の願書も提出</p>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-cook">募集期間</h1>
            <p>
              2023年10月2日(月)～2024年1月25日(木)まで
              <br>9時00分～16時30分まで（土曜日は14時30分まで）
            </p>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-cook">出願手続き</h1>
            <ol>
              <li>入学検定料20,000円</li>
              <li>入学願書（本校所定）</li>
              <li>調査書（公立高等学校向けの用紙に、出身校にて記載したもの）</li>
              <li>写真3枚（3枚とも裏面に氏名を記入し、内2枚は願書・受験票に貼付、内1枚はそのまま提出）</li>
            </ol>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-cook">選考日時</h1>
            <p>2024年1月26日(金) 9時00分より</p>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-cook">選考会場</h1>
            <p>厚木総合専門学校 3階</p>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-cook">選考方法</h1>
            <p>基礎学力テスト・作文・個別面接</p>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-cook">合格発表</h1>
            <p>
              2024年1月31日(水) に本人宛てに合否結果通知書を郵送します。
            </p>
            <ul class="c-note-list">
              <li>通知がお手元に届く日ではありません。</li>
            </ul>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-cook">入学手続き</h1>
            <p>
              合格者は、厚木総合専門学校へ入学金を納入ください。
              <br>下記の日程までに納入されない場合は、入学取り消しとなります。
            </p>
            <div class="c-spacer"></div>
            <table class="c-table">
              <tr>
                <th>専願</th>
                <td>2024年2月8日(木)まで</td>
              </tr>
              <tr>
                <th>併願</th>
                <td>2024年3月7日(木)まで（校納金含む）<br>併願の延納届けは不要です。</td>
              </tr>
            </table>
            <ul class="c-note-list">
              <li>二次募集は状況によっては募集いたしますのでお問い合わせください。</li>
            </ul>
            <section>
              <h1 class="c-heading-4 c-heading-4--theme-cook">就学支援金・学費補助について</h1>
              <p>
                高等学校等就学支援金（国の補助）は、私立高校生等に対して支援金を支給（学校設置者が代理受理）し、 授業料の一部を助成することにより教育費負担の軽減を図るものです。
                <br>（2022年度実績では、月額9,900円／年額118,800円支給）
                <br>（2023年度の授業料負担額昼間部 1ヶ月は、37,000円-9,900円＝27,100円の見込みです）
              </p>
              <p>
                神奈川県より高等学校に学ぶ生徒（保護者と共に県内在住）のうち、保護者の所得が規定の金額以下の場合、申請により学費の一部が入学後補助されます。
              </p>
              <ul class="c-note-list">
                <li>どちらも、保護者の所得に応じて申請によりさらに加算される場合があります。</li>
              </ul>
            </section>
            <section>
              <h1 class="c-heading-4 c-heading-4--theme-cook">高等学校卒業資格取得コースについて</h1>
              <p>入学と同時に姉妹校の厚木中央高等学校に在学となります。</p>
            </section>
          </section>
        </section>
      </intersection-area>
      <intersection-area data-disable="{ state.disableIntersection }" data-id="payment"
        data-intersection-handler="{intersectionHandler}">
        <section id="payment">
          <ui-heading data-tag="h1" data-theme="cook">校納金 支払い手順</ui-heading>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-cook">入学時納付金額</h1>
            <table class="c-table o-space">
              <tr>
                <th>調理学科</th>
                <td>
                  612,000円（17才～大人の方）
                  <br>552,600円（中学卒業見込みの方）
                </td>
              </tr>
            </table>
            <section>
              <h1 class="c-heading-5 c-heading-5--theme-cook">内訳</h1>
              <ul class="c-list c-list--theme-cook">
                <li>入学金</li>
                <li>教育充実費</li>
                <li>維持費</li>
                <li>実習費</li>
                <li>授業料4月〜9月（前期分）</li>
              </ul>
              <table class="c-table o-space">
                <tr>
                  <th>授業料：17才～大人の方</th>
                  <td>昼：@37,000×6ヶ月=¥222,000</td>
                </tr>
                <tr>
                  <th>授業料：中学卒業見込みの方</th>
                  <td>昼：@27,100×6ヶ月=¥162,600</td>
                </tr>
              </table>
            </section>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-cook">教材費納入（3月中旬）</h1>
            <table class="c-table o-space">
              <tr>
                <th>調理学科</th>
                <td>約220,000円～230,000円<br>※物価高騰により変動有</td>
              </tr>
            </table>
            <section>
              <h1 class="c-heading-5 c-heading-5--theme-cook">内訳</h1>
              <ul class="c-list c-list--theme-cook">
                <li>包丁セット</li>
                <li>白衣（和1セット</li>
                <li>洋1セット）</li>
                <li>教科書全巻</li>
                <li>調理実習テキスト（和洋中）</li>
                <li>食品成分表</li>
                <li>行事費</li>
                <li>その他</li>
                <li>昼：6月の健康診断、検便費用、給食費用全て含む。</li>
              </ul>
            </section>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-cook">入学して10月〜3月（後期分）</h1>
            <table class="c-table o-space">
              <tr>
                <th>調理学科</th>
                <td>282,000円（17才～大人の方）
                  <br>222,600円（中学卒業見込みの方）
                </td>
              </tr>
            </table>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-cook">その他かかる費用</h1>
            <p>※費用については変動する場合があります。</p>
            <table class="c-table o-space">
              <tr>
                <th>技術考査試験（問題集含む）</th>
                <td>4,300円</td>
              </tr>
              <tr>
                <th>夏季研修</th>
                <td>交通費（定期範囲外は自己負担）</td>
              </tr>
              <tr>
                <th><span class="c-label c-label--theme-cook c-label--small">希望者のみ</span> 食育インストラクター認定試験</th>
                <td>教本含む6,000円</td>
              </tr>
              <tr>
                <th><span class="c-label c-label--theme-cook c-label--small">希望者のみ</span> クラブ（チョークアート・カービング）</th>
                <td>参加費 1回につき100円</td>
              </tr>
              <tr>
                <th><span class="c-label c-label--theme-cook c-label--small">希望者のみ</span> 和食クラブ</th>
                <td>参加費 1回につき時価（土曜日のみ開催）</td>
              </tr>
            </table>
            <h2 class="c-heading-5 c-heading-5--theme-cook">厚木中央高等学校との同時入学の場合</h2>
            <h3 class="c-heading-6 c-heading-6--theme-cook">2年次</h3>
            <table class="c-table o-space">
              <tr>
                <th>厚木中央高等学校 授業料</th>
                <td>444,000円</td>
              </tr>
              <tr>
                <th>調理実習費</th>
                <td>120,000円</td>
              </tr>
              <tr>
                <th>合計</th>
                <td>564,000円</td>
              </tr>
            </table>
            <h3 class="c-heading-6 c-heading-6--theme-cook">3年次</h3>
            <table class="c-table o-space">
              <tr>
                <th>厚木中央高等学校 授業料</th>
                <td>444,000円</td>
              </tr>
              <tr>
                <th>調理実習費</th>
                <td>120,000円</td>
              </tr>
              <tr>
                <th>合計</th>
                <td>564,000円</td>
              </tr>
            </table>
          </section>
        </section>
      </intersection-area>
    </section>
  </div>
  <script>
    export default {
      state: {
        currentTab: 'expenses',
        disableIntersection: false
      },
      tabs: [
        {
          name: '学費',
          slug: 'expenses'
        },
        {
          name: '17歳〜大人の方',
          slug: 'adult-from-17'
        },
        {
          name: '中学卒業見込みの方',
          slug: 'graduate'
        },
        {
          name: '推薦入学志願者',
          slug: 'recommended'
        },
        {
          name: '一般入学志願者',
          slug: 'general'
        },
        {
          name: '校納金 支払い手順',
          slug: 'payment'
        },
      ],
      clickTabHandler(slug) {
        this.state.disableIntersection = true
        this.state.currentTab = slug
        this.update()
        window.requestAnimationFrame(() => {
          window.location.href = `#${slug}`
        })
      },
      intersectionHandler(slug) {
        this.state.currentTab = slug
        this.update()
      },
      onMounted() {
        document.documentElement.style.setProperty('--mobile-scroll-offset-tab', `50px`)
        this.scrollToHashedElement()
      },
      onUpdated() {
        if (this.state.disableIntersection) {
          if (this.timeoutId) window.clearTimeout(this.timeoutId)
          this.timeoutId = window.setTimeout(() => {
            this.update({ disableIntersection: false })
          }, 1000)
        }
      }
    }
  </script>
</page-atsugi-cook-application>