<page-suzuki-history>
  <section class="o-layout o-layout--column">
    <div>
      <ui-heading data-tag="h1">理事長ご挨拶</ui-heading>
    </div>
    <div>
      <div class="c-top-message">
        <div class="o-layout o-layout--column">
          <div>
            <p>
              皆様が本校入学を決意したからには、その気持ちを忘れずに、自己を見つめ、今自分に出来る事は何であるか、常に自問自答して下さい。
              <br>自分の力を精一杯出し、一つ一つのハードルを乗り越えてください。
            </p>
            <p>高等学校におきましては、自己実現と人間としての成長を、専門課程や専修学校におきましては、技術と専門性を身につけ社会に貢献できる人物になれるよう、私たち学園スタッフが全力を挙げて協力する事をお約束いたします。
            </p>
            <p>
              「一人ひとりの個性を伸ばす教育」
              <br>「挨拶、協調性、人とのつながり 絆」
              <br>「社会のニーズに対応できる知識と技能」
              <br>「自己表現能力と聴く力」
            </p>
            <p>そんな人間作りを目指します。</p>
          </div>
          <div>
            <div class="o-layout o-layout--main-axis-end o-layout--cross-axis-center">
              <div>
                <img src="/img/top-message-photo-2.webp" alt="" class="c-top-message__photo">
              </div>
              <div class="o-cancel-first-child-gap">
                <p>鈴木学園 理事長</p>
                <p class="c-top-message__name">鈴木織江</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="o-spacer o-spacer--large-2"></div>
  <section class="o-layout o-layout--column">
    <div>
      <ui-heading data-tag="h1">沿革</ui-heading>
    </div>
    <div>
      <table class="c-table">
        <tr>
          <th>昭和11年4月</th>
          <td>「スズキ学院」設立。学校として第一歩を歩みはじめる。</td>
        </tr>
        <tr>
          <th>昭和25年9月</th>
          <td>学校教育法に基づく学校として県知事より認可される。</td>
        </tr>
        <tr>
          <th>昭和44年4月</th>
          <td>学院の技能教育の優秀さが認められ、文部大臣指定校となり、
            神奈川県立厚木南高等学校の技能連携校として高等学校の卒業資格も併せて取得することが可能となる。</td>
        </tr>
        <tr>
          <th>昭和51年</th>
          <td>「専修学校制度」発足。
            厚木調理師学校開校
          </td>
        </tr>
        <tr>
          <th>昭和52年4月</th>
          <td>学校法人の運営に委ねる。</td>
        </tr>
        <tr>
          <th>昭和53年4月</th>
          <td>高等課程を厚木高等専修学校に、専門課程を厚木文化専門学校と改名。</td>
        </tr>
        <tr>
          <th>昭和60年4月</th>
          <td>厚木高等専修学校を厚木市中町より現在の厚木市恩名1-17-18に移転、工業技術学科を設置する。</td>
        </tr>
        <tr>
          <th>平成2年4月</th>
          <td>厚木市中町3-4-11に関東歯科衛生士専門学校を設置する。</td>
        </tr>
        <tr>
          <th>平成3年4月</th>
          <td>厚木高等専修学校に専門課程自動車整備科を設置する。</td>
        </tr>
        <tr>
          <th>平成6年4月</th>
          <td>厚木高等専修学校に生活造形学科を設置する。
            厚木高等専修学校服飾技術科を生活造形学科の中に組み入れ、
            ファッションコース、生活コースに分け、認可を受ける。</td>
        </tr>
        <tr>
          <th>平成7年4月</th>
          <td>私立向陽台高等学校の厚木キャンパスとなる。</td>
        </tr>
        <tr>
          <th>平成13年4月</th>
          <td>厚木中央高等学校開校。</td>
        </tr>
        <tr>
          <th>平成22年4月</th>
          <td>関東歯科衛生士専門学校、2年制から3年制に変更。</td>
        </tr>
        <tr>
          <th>平成24年4月</th>
          <td>厚木高等専修学校から専門学校　神奈川総合大学校に改名。
            自動車整備科に一級自動車整備科を発足。</td>
        </tr>
        <tr>
          <th>令和2年4月</th>
          <td>厚木調理師学校 第2校舎を厚木市中町3-4-11に開校。</td>
        </tr>
        <tr>
          <th>令和3年4月</th>
          <td>厚木調理師学校、厚木文化専門学校、関東歯科衛生士専門学校を統合し、厚木総合専門学校としてリニューアル</td>
        </tr>
      </table>
    </div>
  </section>
  <script>
    export default {
      onMounted() {
        this.scrollToHashedElement()
      }
    }
  </script>
</page-suzuki-history>