<page-chuo-open-campus>
  <div class="c-editor-content c-editor-content--theme-chuo">
    <section>
      <ui-heading data-tag="h1" data-theme="chuo">
        <small>2023年度 厚木中央高校<br>普通科/工業科</small>
        <br>体験入学
      </ui-heading>
      <div class="c-editor-content c-editor-content--theme-chuo">
        <section class="c-editor-content">
          <h1 class="c-heading-4 c-heading-4--theme-chuo">学校説明会</h1>
          <p>
            学校説明会
            <br>10:00〜12:00
          </p>
          <table class="c-table c-table--keep-layout o-space">
            <tr>
              <th style="width:8em;">内容</th>
              <th>日付</th>
              <th>体験授業の内容</th>
            </tr>
            <tr>
              <th rowspan="4">
                入学説明会
                <br>（学校説明のみ）
              </th>
              <td>11月11日（土）</td>
              <td rowspan="4">
                10:00〜12:00
                <ul>
                  <li>学校説明会</li>
                  <li>施設見学</li>
                  <li>入試内容</li>
                  <li>国県支援金</li>
                  <li>個別相談</li>
                </ul>
                ※実習はありません。
              </td>
            </tr>
            <tr>
              <td>11月25日（土）</td>
            </tr>
            <tr>
              <td>12月2日（土）</td>
            </tr>
            <tr>
              <td>12月9日（土）</td>
            </tr>
          </table>
          <ul class="c-note-list">
            <li>日付によって体験授業の内容が異なりますので、注意してお申込み下さい。</li>
          </ul>
        </section>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-chuo">当日の持ち物</h1>
          <ul>
            <li>上履き</li>
            <li>筆記用具</li>
          </ul>
        </section>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-chuo">参加費用</h1>
          <div class="c-content">
            <p>無料</p>
          </div>
        </section>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-chuo" id="form">お申し込みフォーム</h1>
          <template if="{state.mode != 'thanks'}">
            <form action="">
              <div class="c-content">
                <p>各日程前日までに、下記の申し込みフォームよりお申し込みください。</p>
              </div>
              <div class="o-space">
                <table class="c-table">
                  <tr>
                    <th>
                      <div class="o-layout">
                        <div class="o-size-fill">お名前</div>
                        <div>
                          <span class="c-label c-label--theme-chuo">必須</span>
                        </div>
                      </div>
                    </th>
                    <td>
                      <template if="{state.mode === 'confirm'}">
                        {state.dataSubmit['名前']}
                      </template>
                      <input data-ref-input="名前" hidden="{state.mode === 'confirm'}" type="text" class="c-input-text">
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <div class="o-layout">
                        <div class="o-size-fill">フリガナ</div>
                        <div>
                          <span class="c-label c-label--theme-chuo">必須</span>
                        </div>
                      </div>
                    </th>
                    <td>
                      <template if="{state.mode === 'confirm'}">
                        {state.dataSubmit['フリガナ']}
                      </template>
                      <input data-ref-input="フリガナ" hidden="{state.mode === 'confirm'}" type="text" class="c-input-text">
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <div class="o-layout">
                        <div class="o-size-fill">メールアドレス</div>
                        <div>
                          <span class="c-label c-label--theme-chuo">必須</span>
                        </div>
                      </div>
                    </th>
                    <td>
                      <template if="{state.mode === 'confirm'}">
                        {state.dataSubmit['メールアドレス']}
                      </template>
                      <input data-ref-input="メールアドレス" hidden="{state.mode === 'confirm'}" type="email"
                        class="c-input-text">
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <div class="o-layout">
                        <div class="o-size-fill">中学校名</div>
                        <div>
                          <span class="c-label c-label--theme-chuo">必須</span>
                        </div>
                      </div>
                    </th>
                    <td>
                      <template if="{state.mode === 'confirm'}">
                        {state.dataSubmit['中学校名']}
                      </template>
                      <input data-ref-input="中学校名" hidden="{state.mode === 'confirm'}" type="text" class="c-input-text">
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <div class="o-layout">
                        <div class="o-size-fill">参加日</div>
                        <div>
                          <span class="c-label c-label--theme-chuo">必須</span>
                        </div>
                      </div>
                    </th>
                    <td>
                      <template if="{state.mode === 'confirm'}">
                        {state.dataSubmit['参加日']}
                      </template>
                      <select name="date" hidden="{state.mode === 'confirm'}" data-ref-input="参加日">
                        <option value="">選択してください</option>
                        <template if="{null}">
                          <option value="体験入学&学校説明会 2023年6月17日(土) 10:00〜">体験入学&amp;学校説明会 2023年6月17日(土) 10:00〜</option>
                          <option value="体験入学&学校説明会 2023年7月15日(土) 10:00〜">体験入学&amp;学校説明会 2023年7月15日(土) 10:00〜</option>
                          <option value="体験入学&学校説明会 2023年7月29日(土) 10:00〜">体験入学&amp;学校説明会 2023年7月29日(土) 10:00〜</option>
                          <option value="体験入学&学校説明会 2023年8月9日(水) 10:00〜">体験入学&amp;学校説明会 2023年8月9日(水) 10:00〜</option>
                          <option value="体験入学&学校説明会 2023年8月26日(土) 10:00〜">体験入学&amp;学校説明会 2023年8月26日(土) 10:00〜</option>
                          <option value="体験入学&学校説明会 2023年9月16日(土) 10:00〜">体験入学&amp;学校説明会 2023年9月16日(土) 10:00〜</option>
                          <option value="体験入学&学校説明会 2023年9月30日(土) 10:00〜">体験入学&amp;学校説明会 2023年9月30日(土) 10:00〜</option>
                          <option value="体験入学&学校説明会 2023年10月7日(土) 10:00〜">体験入学&amp;学校説明会 2023年10月7日(土) 10:00〜</option>
                          <option value="体験入学&学校説明会 2023年10月7日(土) 10:00〜">体験入学&amp;学校説明会 2023年10月7日(土) 10:00〜</option>
                        </template>
                        <option value="入学説明会 2023年11月11日(土) 10:00〜">入学説明会 2023年11月11日(土) 10:00〜</option>
                        <option value="入学説明会 2023年11月25日(土) 10:00〜">入学説明会 2023年11月25日(土) 10:00〜</option>
                        <option value="入学説明会 2023年12月2日(土) 10:00〜">入学説明会 2023年12月2日(土) 10:00〜</option>
                        <option value="入学説明会 2023年12月9日(土) 10:00〜">入学説明会 2023年12月9日(土) 10:00〜</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <div class="o-layout">
                        <div class="o-size-fill">参加人数</div>
                        <div>
                          <span class="c-label c-label--theme-chuo">必須</span>
                        </div>
                      </div>
                    </th>
                    <td>
                      <template if="{state.mode === 'confirm'}">
                        {state.dataSubmit['参加人数']}
                      </template>
                      <input data-ref-input="参加人数" hidden="{state.mode === 'confirm'}" type="number" min="1" length="2"
                        class="c-input-text c-input-text--size-auto">
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <div class="o-layout">
                        <div class="o-size-fill">電話番号</div>
                      </div>
                    </th>
                    <td>
                      <template if="{state.mode === 'confirm'}">
                        {state.dataSubmit['電話番号']}
                      </template>
                      <input data-ref-input="電話番号" hidden="{state.mode === 'confirm'}" type="tel" min="1"
                        class="c-input-text">
                    </td>
                  </tr>
                  <tr>
                    <th>備考</th>
                    <td>
                      <template if="{state.mode === 'confirm'}">
                        <raw-html data-content="{breakToBr(state.dataSubmit['備考'])}"></raw-html>
                      </template>
                      <textarea data-ref-input="備考" hidden="{state.mode === 'confirm'}" class="c-textarea" cols="30"
                        rows="5"></textarea>
                    </td>
                  </tr>
                </table>
                <div class="o-items-center o-space">
                  <div class="o-layout">
                    <div if="{state.mode === 'input'}">
                      <ui-button data-tag="button" data-class="theme-chuo"
                        onclick="{clickConform}">入力内容を確認する</ui-button>
                    </div>
                    <template if="{state.mode === 'confirm'}">
                      <div>
                        <ui-button data-tag="button" data-class="theme-chuo" onclick="{backToInput}">修正する</ui-button>
                      </div>
                      <div>
                        <ui-button data-tag="button" data-class="theme-chuo" onclick="{clickSubmit}"
                          data-button-type="submit">この内容で送信する</ui-button>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </form>
          </template>
          <template if="{state.mode === 'thanks'}">
            <section>
              <h1 class="c-heading-5 c-heading-5--theme-chuo">お問い合わせ頂きありがとうございます。</h1>
            </section>
          </template>
        </section>
      </div>
    </section>
  </div>
  <script>
    export default {
      state: {
        /**
         * @type {('input'|'confirm'|'thanks')} mode
         */
        mode: 'input',
        /**
         * @type {Object} formData
         */
        formData: {
          '名前': { require: true, value: '' },
          'フリガナ': { require: true, value: '' },
          'メールアドレス': { require: true, value: '' },
          '中学校名': { require: true, value: '' },
          '参加日': { require: true, value: '' },
          '参加人数': { require: true, value: '' },
          '電話番号': { require: false, value: '' },
          '備考': { require: false, value: '' },
        },
        /**
         * フォームに送信するデータ
         */
        dataSubmit: {},
      },
      /**
       * @return {Boolean}
       */
      getIsInlineForm() {
        const result = !this.state.schoolSlug.match(/(cook|design|dental)/)
        return result
      },
      goToForm() {
        if (this.getIsInlineForm()) {
          this.setPageStateSlug('form')
        }
        else {
          window.open('https://www.school-go.info/f9z653/atsugi/form.php?fno=23&fsno=1&openExternalBrowser=1', '_blank')
        }
      },
      /**
       * @param {String} slug
       */
      setPageStateSlug(slug) {
        this.state.pageStateSlug = slug
        this.update()
        window.scrollTo(0, 0)
      },
      clickConform(event) {
        event.preventDefault()
        this.setFormData()
      },
      resetData() {
        this.state.formData = this.state.formDataDefault
        this.state.dataSubmit = {}
      },
      setFormData() {
        this.resetData()
        Object.keys(this.state.formData).forEach(key => {
          // console.log(key)
          this.state.formData[key].value = this.$(`[data-ref-input="${key}"]`).value
          this.state.dataSubmit[key] = this.state.formData[key].value
        })
        this.state.dataSubmit['問い合わせ先の学校名'] = '厚木中央高等学校'
        this.state.dataSubmit['タイトル'] = '体験入学'
        this.state.dataSubmit['管理先メールアドレス'] = this.dataset.emails.chuo
        this.checkRequiredValue()
      },
      checkRequiredValue() {
        const result = Object.keys(this.state.formData).some(key => {
          return !this.state.formData[key].value && this.state.formData[key].require
        })
        if (result) {
          alert('恐れ入りますが入力に不足があるようです。\n「必須」と表示された入力欄に正しく記入されたかご確認ください。')
        }
        else {
          this.goToConfirm()
        }
      },
      goToConfirm() {
        this.state.mode = 'confirm'
        this.update()
        window.location.href = '#form'
      },
      backToInput() {
        this.state.mode = 'input'
        this.update()
        window.location.href = '#form'
      },
      clickSubmit(event) {
        event.preventDefault()
        this.submitForm()
      },
      submitForm() {
        if (!this.state.submitted) {
          this.state.submitted = true
          fetch('/cockpit/api/forms/submit/opencampus', {
            method: 'post',
            credentials: "same-origin",
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer 35caad65cd1c9add5bdeb758fd62a0',
            },
            body: JSON.stringify({
              form: this.state.dataSubmit
            })
          })
            .then(result => result.json())
            .then(result => {
              console.log(result)
              this.state.submitted = false
              this.goToThanks()
            })
            .catch(error => {
              console.log(error)
              alert('申し訳ありませんが、送信時に予期せぬエラーが発生しました。\nしばらく経ってから再度お試しください。')
              this.state.submitted = false
            })
        }
      },
      goToThanks() {
        this.state.mode = 'thanks'
        this.update()
        window.location.href = '#form'
      },
      backupDefaultData() {
        this.state.formDataDefault = JSON.parse(JSON.stringify(this.state.formData))
      },
      onBeforeMount() {
        this.backupDefaultData()
      },
      onMounted() {
        this.scrollToHashedElement()
      }
    }
  </script>
</page-chuo-open-campus>