<group-footer class="c-group-footer">
  <div class="o-layout o-layout--column">
    <div>
      <ul class="o-layout o-layout--column o-layout--gap-none c-group-menu">
        <li each="{ menu in menus }" class="{ getMenuClass(menu) }">
          <group-nav-link data-item="{ menu }"></group-nav-link>
          <ul class="o-layout o-layout--column o-layout--gap-none c-group-menu c-group-menu--2nd"
            if="{ menu.children }">
            <li each="{ menu2 in menu.children}">
              <group-nav-link data-item="{ menu2 }" data-is-child="{ true }"></group-nav-link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div>
      <div class="c-footer-last">
        <div class="o-container o-items-center">
          <small class="c-copyright">Copyright ©︎ { new Date().getFullYear() } SUZUKI GAKUEN All Rights
            Reserved.</small>
        </div>
      </div>
    </div>
  </div>
  <script>
    // @ts-check
    export default function GroupFooter() {
      return {
        /**
         * @typedef {object} menu
         * @property {boolean} [isCorporate]
         * @property {string} title
         * @property {string} slug
         * @property {string} [href]
         * @property {menu[]} [children]
         * @property {string} [photo]
         */
        /**
         * @type {menu[]}
         */
        menus: [
          {
            isCorporate: true,
            title: '鈴木学園法人Webサイト',
            slug: '',
          },
          {
            title: '厚木中央高等学校',
            slug: 'chuo',
            photo: '/img/photo-chuo.webp'
          },
          {
            title: '専門学校神奈川総合大学校',
            slug: 'kanagawa',
            photo: '/img/photo-kanagawa.webp',
            children: [
              {
                title: '高等課程',
                slug: 'highschool',
                href: '/kanagawa/highschool',
              },
              {
                title: '専門課程 自動車整備科',
                slug: 'car',
                href: '/kanagawa/car',
              }
            ]
          },
          {
            title: '厚木総合専門学校',
            slug: 'atsugi',
            photo: '/img/photo-atsugi.webp',
            children: [
              {
                title: '調理学科',
                slug: 'cook',
                href: '/atsugi/cook',
              },
              {
                title: '生活デザイン学科',
                slug: 'design',
                href: '/atsugi/design',
              },
              {
                title: '歯科衛生学科',
                slug: 'dental',
                href: '/atsugi/dental',
              }
            ]
          },
        ],
        /**
         * @param {menu} menuItem
         * @return {string}
         */
        getMenuClass(menuItem) {
          if (menuItem.isCorporate) {
            return 'c-group-menu__corporate-item'
          }
        }
      }
    }
  </script>
</group-footer>