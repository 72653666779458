<page-atsugi-design-application>
  <div class="c-content">
    <section>
      <ui-heading data-tag="h1" data-theme="design">2024年度<br>厚木総合専門学校 生活デザイン学科<br>学生募集要項</ui-heading>
      <table class="c-table o-space">
        <tr>
          <th>出願基準</th>
          <td>
            高等学校卒業見込、及び高等学校卒業又は高等学校卒業と同等以上の学力と資格を有す者
            <br>高等専修学校 高等課程3年修了見込、及び修了の者
          </td>
        </tr>
        <tr>
          <th>募集人員</tfh>
          <td>昼間部40名 夜間部40名</td>
        </tr>
        <tr>
          <th>募集コース</th>
          <td>ITコース、ファッションコース</td>
        </tr>
        <tr>
          <th>修業年限</th>
          <td>2年</td>
        </tr>
        <tr>
          <th>入学期</th>
          <td>4月</td>
        </tr>
      </table>
      <div class="o-spacer"></div>
      <div class="c-sticky-block">
        <ui-tabs data-tabs="{tabs}" data-current="{state.currentTab}" data-theme="design"
          data-tab-click-handler="{clickTabHandler}">
        </ui-tabs>
      </div>
      <intersection-area data-disable="{ state.disableIntersection }" data-id="expenses"
        data-intersection-handler="{intersectionHandler}">
        <section id="expenses">
          <ui-heading data-tag="h1" data-theme="design">学費</ui-heading>
          <div class="c-items-scroll">
            <table class="c-table c-table--keep-layout" style="min-width: 768px;">
              <tr>
                <th></th>
                <th>入学金</th>
                <th>教育充実費</th>
                <th>教育運営費</th>
                <th>生徒諸費</th>
                <th>授業料（12ヶ月）</th>
                <th><span class="c-label c-label--theme-design">合計</span></th>
              </tr>
              <tr>
                <th>1年次</th>
                <td>160,000円</td>
                <td>120,000円</td>
                <td>30,000円</td>
                <td>60,000円</td>
                <td>324,000円</td>
                <td>724,000円</td>
              </tr>
              <tr>
                <th>2年次</th>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>60,000円</td>
                <td>324,000円</td>
                <td>384,000円</td>
              </tr>
              <tr>
                <th>夜 1年次</th>
                <td>40,000円</td>
                <td>30,000円</td>
                <td>-</td>
                <td>30,000円</td>
                <td>204,000円</td>
                <td>334,000円</td>
              </tr>
              <tr>
                <th>夜 2年次</th>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>30,000円</td>
                <td>204,000円</td>
                <td>234,000円</td>
              </tr>
            </table>
          </div>
          <ul class="c-list c-list--theme-design">
            <li>教本・教材・行事費などで1年次22万5千円、2年次9万2千円が必要</li>
            <li>授業料、実習費は6ヶ月分を入学時と9月の2回に分納する。事情のある方は相談に応じますので、お申し出下さい。</li>
            <li>学費は合格決定後、指定期日までに納入して下さい｡</li>
            <li>一旦納入した学費は、理由を問わず返還しません。</li>
            <li>分割の方法もありますので、納入日の前にお問合せ下さい｡</li>
            <li>3月31日までに入学辞退の申し出があった場合、入学金及び検定料以外の校納金を返還します。</li>
          </ul>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-design">親族入学支援制度</h1>
            <p>入学希望者の親族が本校卒業生、もしくは在学生である場合、入学金を100%または50%免除する制度です。</p>
            <p>（同法人からの入学者、同時入学も免除の対象になります。）</p>
            <section>
              <h2 class="c-heading-5 c-heading-5--theme-design">対象入学</h2>
              <p>全入学（出願）方法</p>
            </section>
            <section>
              <h2 class="c-heading-5 c-heading-5--theme-design">支援資格</h2>
              <section>
                <h3 class="c-heading-6 c-heading-6--theme-design">入学金100%免除</h3>
                <ul class="c-list c-list--theme-design">
                  <li>同法人からの入学者及び本校に在籍または同時入学、本校の卒業生が2親等以内にいる場合</li>
                </ul>
              </section>
              <section>
                <h3 class="c-heading-6 c-heading-6--theme-design">入学金50%免除</h3>
                <ul class="c-list c-list--theme-design">
                  <li>同法人に在籍または同時入学、同法人の卒業生が2親等以内にいる場合</li>
                </ul>
              </section>
              <ul class="c-note-list">
                <li>1親等：父母、子</li>
                <li>2親等：兄弟姉妹、祖父母、孫</li>
                <li>3親等：伯父・伯母、甥・姪、曽祖父母、曽孫</li>
              </ul>
            </section>
            <section>
              <h2 class="c-heading-5 c-heading-5--theme-design">申請手続き</h2>
              <p>上記「支援資格」に該当する方は、入学願書の所定欄に氏名（旧姓）、続柄、学校名を記入してください。</p>
            </section>
            <section>
              <h2 class="c-heading-5 c-heading-5--theme-design">親族入学支援制度の注意事項</h2>
              <p>中途退学した場合、退学時に免除額の全額を返済していただきます。<br>該当者には合格証と一緒に誓約書を同封。</p>
            </section>
          </section>
          <section>
            <h2 class="c-heading-4 c-heading-4--theme-design">奨学制度</h2>
            <ul>
              <li>生活デザイン学科では日本学生支援機構の奨学金制度を利用することが出来ます。</li>
              <li>
                2つの方法がありますが、どちらも家計や学力など一定の基準を満たしていることが必要です。
                <ol>
                  <li>予約採用：高校在学中に申し込んでおくことができます。（詳しくは各高等学校でお尋ねください。）</li>
                  <li>在学採用：本校に入学後、申し込み。（1種・2種の審査については日本学生支援機構が行います。）</li>
                </ol>
              </li>
            </ul>
          </section>
          <section>
            <h2 class="c-heading-4 c-heading-4--theme-design">教育ローン</h2>
            <ul>
              <li>国の教育ローン（日本政府金融公庫）…融資額300万円以内、返済期間15年</li>
            </ul>
          </section>
          <section>
            <h2 class="c-heading-4 c-heading-4--theme-design">奨学金返済助成制度</h2>
            <p>
              下記の奨学金返済助成制度は卒業後、厚木市内の企業に勤務した場合、お手続きをし交付を受けるものとなります。
              <br>卒業時に実施されるかは各自、各担当部署にお問い合わせください。
              <br>また、交付を受けるにあたっての条件もご確認ください。
            </p>
            <ul>
              <li>厚木市勤労者奨学金返済支援事業（2023年4月現在）</li>
            </ul>
          </section>
        </section>
      </intersection-area>
      <intersection-area data-disable="{ state.disableIntersection }" data-id="recommended-from-designated"
        data-intersection-handler="{intersectionHandler}">
        <section id="recommended-from-designated">
          <ui-heading data-tag="h1" data-theme="design">出願手続および選考：<br><strong>指定校制学校推薦型選抜</strong></ui-heading>
          <p>指定校になっているのか不明の場合や出願、選考などにつきましては、高校の進路担当者もしくは厚木総合専門学校 生活デザイン学科にお問い合わせください。</p>
          <ul class="c-note-list">
            <li>指定校制学校推薦型選抜で出願された場合、基本的に不合格にはなりません。</li>
          </ul>
        </section>
      </intersection-area>
      <intersection-area data-disable="{ state.disableIntersection }" data-id="recommended"
        data-intersection-handler="{intersectionHandler}">
        <section id="recommended">
          <ui-heading data-tag="h1" data-theme="design">出願手続および選考：<br><strong>公募制学校推薦選抜</strong></ui-heading>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-design">出願書類</h1>
            <ul class="c-list c-list--theme-design">
              <li>最終出身学校長の推薦状（本校指定）</li>
              <li>入学願書（本校指定）</li>
              <li>卒業見込証明書または卒業証明書（卒業見込み：調査書内、記載可）</li>
              <li>最終出身学校の調査書</li>
              <li>写真3枚（上半身4cm×3cm 3ヶ月以内に撮影）<br>3枚とも裏面に氏名を記入し、内2枚は願書・受験票に貼付、内1枚はそのまま提出</li>
              <li>入学検定料20,000円</li>
            </ul>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-design">選考方法</h1>
            <p>面接</p>
          </section>
        </section>
      </intersection-area>
      <intersection-area data-disable="{ state.disableIntersection }" data-id="general"
        data-intersection-handler="{intersectionHandler}">
        <section id="general">
          <ui-heading data-tag="h1" data-theme="design">出願手続および選考：<br><strong>一般選抜</strong></ui-heading>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-design">出願書類</h1>
            <ul class="c-list c-list--theme-design">
              <li>入学願書（本校指定）</li>
              <li>卒業見込証明書または卒業証明書（卒業見込み：調査書内、記載可）</li>
              <li>最終出身学校の調査書</li>
              <li>写真3枚（上半身4cm×3cm 3ヶ月以内に撮影）<br>3枚とも裏面に氏名を記入し、内2枚は願書・受験票に貼付、内1枚はそのまま提出</li>
              <li>入学検定料20,000円</li>
            </ul>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-design">選考方法</h1>
            <p>書類審査、面接</p>
          </section>
        </section>
      </intersection-area>
      <intersection-area data-disable="{ state.disableIntersection }" data-id="schedule"
        data-intersection-handler="{intersectionHandler}">
        <section id="schedule">
          <section>
            <ui-heading data-tag="h1" data-theme="design">推薦・一般選抜日程</ui-heading>
            <div class="c-items-scroll o-space">
              <table class="c-table c-table--keep-layout" style="min-width: 768px;">
                <tbody>
                  <tr>
                    <th>募集区分</th>
                    <th>原書提出</th>
                    <th>入試</th>
                    <th>合格発表<br>郵送</th>
                    <th>手続き〆切</th>
                  </tr>
                  <tr>
                    <td>第一回</td>
                    <td>2023年10月2日(月)<br>～2023年10月12日(木)</td>
                    <td>2023年10月14日(土)<br>9時30分</td>
                    <td>2023年10月17日(火)<br>郵送</td>
                    <td>2023年10月24日(火)</td>
                  </tr>
                  <tr>
                    <td>第二回</td>
                    <td>2023年10月16日(月)<br>～2023年11月2日(木)</td>
                    <td>2023年11月4日(土)<br>9時30分</td>
                    <td>2023年11月7日(火)<br>郵送</td>
                    <td>2023年11月17日(金)</td>
                  </tr>
                  <tr>
                    <td>第三回</td>
                    <td>2023年11月6日(月)<br>～2023年11月30日(木)</td>
                    <td>2023年12月2日(土)<br>9時30分</td>
                    <td>2023年12月5日(火)<br>郵送</td>
                    <td>2023年12月15日(金)</td>
                  </tr>
                  <tr>
                    <td>第四回</td>
                    <td>2023年12月4日(月)<br>～2024年1月18日(木)</td>
                    <td>2024年1月20日(土)<br>9時30分</td>
                    <td>2024年1月23日(火)<br>郵送</td>
                    <td>2023年2月2日(金)</td>
                  </tr>
                  <tr>
                    <td>第五回</td>
                    <td>2024年1月22日(月)<br>～2024年2月15日(木)</td>
                    <td>2024年2月17日(土)<br>9時30分</td>
                    <td>2024年2月20日(火)<br>郵送</td>
                    <td>2024年2月27日(火)</td>
                  </tr>
                  <tr>
                    <td>第六回</td>
                    <td>2024年2月19日(月)<br>～2024年3月7日(木)</td>
                    <td>2024年3月9日(土)<br>9時30分 </td>
                    <td>2024年3月12日(火)<br>郵送</td>
                    <td>2024年3月18日(月)</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
        </section>
      </intersection-area>
      <intersection-area data-disable="{ state.disableIntersection }" data-id="total"
        data-intersection-handler="{intersectionHandler}">
        <section id="total">
          <ui-heading data-tag="h1" data-theme="design">出願手続および選考：<br><strong>総合型選抜(AO)入試</strong></ui-heading>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-design">アドミッションポリシー</h1>
            <p>厚木総合専門学校は高度な知識と優れた技術を備えた創造性に富む人間性豊かな職業人を育成する事を目標としています。そのために次に掲げるポリシーにより学生を求めます。</p>
            <ul class="c-list c-list--theme-design">
              <li>ITまたはファッションに深い興味・関心を有し、自ら課題に取り組む姿勢を有しているもの。</li>
              <li>多様な価値観を尊重し、高い協調性を持ち、人としての成長を目指す意欲を有しているもの。</li>
            </ul>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-design">出願基準</h1>
            <p>2023年度のオープンキャンパス参加者</p>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-design">総合型選抜の流れ</h1>
            <ol>
              <li>オープンキャンパス</li>
              <li>エントリー提出</li>
              <li>面接</li>
              <li>エントリー合格</li>
              <li>入学願書提出</li>
              <li>合格発表</li>
            </ol>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-design">エントリー書類</h1>
            <ul class="c-list c-list--theme-design">
              <li>総合型選抜エントリーシート（本校指定）</li>
              <li>自己申告書（本校指定）</li>
              <li>OPEN CAMPUS参加証明書</li>
            </ul>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-design">エントリー面接</h1>
            <p>個別面接</p>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-design">エントリー合格発表</h1>
            <p>郵送</p>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-design">出願書類</h1>
            <ul class="c-list c-list--theme-design">
              <li>エントリー合格証</li>
              <li>卒業見込証明書または卒業証明書（卒業見込み：調査書内、記載可）</li>
              <li>入学願書（本校指定）</li>
              <li>成績証明書（または調査書など）</li>
              <li>写真3枚（上半身4cm×3cm 3ヶ月以内に撮影したもの）<br>3枚とも裏面に氏名を記入し、内2枚は願書・受験票に貼付、内1枚はそのまま提出</li>
              <li>入学検定料20,000円</li>
            </ul>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-design">選考方法</h1>
            <p>書類審査（入試日には書類審査のみ行ないますので、来校する必要はありません。）</p>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-design">面接日</h1>
            <p>
              エントリーシートを提出した者について、エントリーシートに書かれた内容について２０分程度の面接を実施します。
            </p>
            <ul class="c-note-list">
              <li>面接日は受験票・筆記用具・上履き持参のこと</li>
            </ul>
            <ul class="c-list c-list--theme-design">
              <li>第1回
                <ul class="c-list c-list--theme-design">
                  <li>2023年6月17日(土) 13:30〜</li>
                  <li>2023年7月15日(土) 13:30〜</li>
                  <li>2023年8月9日(水) 13:30〜</li>
                </ul>
              </li>
              <li>第2回 2023年9月16日(土) 13:30〜</li>
              <li>第3回 2024年2月14日(木) 14:00〜</li>
            </ul>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-design">エントリー合格について</h1>
            <p>
              提出されたエントリーシートと面接の結果で「エントリー合格証」を発行し、郵送します。
              <br>但し「エントリー合格証」が発行されても正式の合格ではありません。
              <br>「エントリー合格証」を添えて本校の入学願書を提出し、総合型選抜合格決定となります。
            </p>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-design">願書について</h1>
            <p>
              願書は入学願書と同じものです。推薦書は必要無し。｢エントリー合格証｣を添えて提出してください。
              <br>願書受付は9月1日開始となります。
            </p>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-design">願書提出期間</h1>
            <ul class="c-list c-list--theme-design">
              <li>第1回:2023年9月1日(金)～2023年9月22日(金)</li>
              <li>第2回:2023年9月26日(火)～2023年10月20日(金)</li>
              <li>第3回:2024年2月19日(月)～2024年2月26日(月)</li>
            </ul>
            <p>
              午前9時～午後4時00分まで受付けます。土曜は午後2時00分まで受付けます。
              <br>日曜、祝祭日は受付業務を行っておりません。
            </p>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-design">総合型選抜 選考日程</h1>
            <div class="c-items-scroll o-space">
              <table class="c-table c-table--keep-layout" style="min-width: 768px;">
                <tbody>
                  <tr>
                    <th>募集区分</th>
                    <th>エントリー期間</th>
                    <th>面接</th>
                    <th>願書提出</th>
                    <th>入試</th>
                    <th>合格発表<br>郵送</th>
                    <th>手続き〆切</th>
                  </tr>
                  <tr>
                    <td>第一回</td>
                    <td rowspan="2">2023年6月1日(木)<br>～2023年9月14日(木)</td>
                    <td class="o-fit">
                      <ul class="c-list c-list--theme-design">
                        <li>2023年6月17日(土) 13:30〜</li>
                        <li>2023年7月15日(土) 13:30〜</li>
                        <li>2023年8月9日(水) 13:30〜</li>
                      </ul>
                    </td>
                    <td>2023年9月1日(金)<br>～2023年9月22日(金)</td>
                    <td>2023年9月26日(火)<br>※書類審査</td>
                    <td>2023年9月28日(木)</td>
                    <td>2023年10月6日(金)</td>
                  </tr>
                  <tr>
                    <td>第二回</td>
                    <td class="o-fit">
                      <ul class="c-list c-list--theme-design">
                        <li>2023年9月16日(土) 13:30〜</li>
                      </ul>
                    </td>
                    <td>2023年9月26日(火)<br>～2023年10月20日(金)</td>
                    <td>2023年10月25日(水)<br>※書類審査</td>
                    <td>2023年10月27日(金)</td>
                    <td>2023年11月6日(月)</td>
                  </tr>
                  <tr>
                    <td>第三回</td>
                    <td>2024年2月1日(木)<br>～2024年2月9日(金)</td>
                    <td class="o-fit">
                      <ul class="c-list c-list--theme-design">
                        <li>2024年2月14日(水) 14:00〜</li>
                      </ul>
                    </td>
                    <td>2024年2月19日(月)<br>～2024年2月26日(月)</td>
                    <td>2024年2月27日(火)<br>※書類審査</td>
                    <td>2024年2月29日(木)</td>
                    <td>2024年3月8日(金)</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
        </section>
      </intersection-area>
      <intersection-area data-disable="{ state.disableIntersection }" data-id="common"
        data-intersection-handler="{intersectionHandler}">
        <section id="common">
          <ui-heading data-tag="h1" data-theme="design">各種選抜共通事項</ui-heading>
          <section>
            <h2 class="c-heading-4 c-heading-4--theme-design">エントリー・出願方法</h2>
            <p>
              エントリー、出願にあたっては【出願書類】をご確認の上、期間内に必着するよう郵送するか、来校にて提出ください。郵送の場合には、検定料も同封し現金書留をご使用ください。（定形外の封筒でも可能です。郵便局にお尋ねください。）
              <br>エントリーシートは面接日の2日前までに必着、または提出してください。
            </p>
          </section>
          <section>
            <h2 class="c-heading-4 c-heading-4--theme-design">エントリー・出願期間</h2>
            <div class="o-spacer"></div>
            <table class="c-table">
              <tr>
                <th>平日</th>
                <td>9時00分〜16時00分</td>
              </tr>
              <tr>
                <th>土曜日</th>
                <td>9時00分〜14時00分</td>
              </tr>
            </table>
            <ul class="c-note-list">
              <li>日曜日、祝祭日、8月11日〜8月17日、12月27日〜1月8日は受付業務を停止しております。<br>10月26日〜10月30日は学園祭予定のため、郵送のみ受付します。</li>
            </ul>
          </section>
          <section>
            <h2 class="c-heading-4 c-heading-4--theme-design">出願についての注意事項</h2>
            <p>
              出願書類に記載された個人情報については、選抜試験、入学手続に関すること以外に使用することはありません。一度受付をした出願書類・検定料は、いかなる理由でも返却しません。
              <br>受験票の返送期間が間に合わないと認められる場合は、入試係で保管しますので、試験日当日受付で受け取ってください。
            </p>
            <p><strong>在籍高等学校調査書（2024年3月高等学校卒業見込者のみ）が出願時に間に合わない場合は柔軟に対応いたしますのでご相談ください。</strong></p>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-design">選考会場</h1>
            <p>厚木総合専門学校 7階 9時30分集合</p>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-design">合格発表</h1>
            <p>
              本人宛に合否結果通知書を郵送します。合格者には入学手続書類も同封します。
              <br>合否結果通知書は合格発表日に郵送します。合格発表より3日経過しても届かない場合には、ご連絡ください。
            </p>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-design">入学手続き</h1>
            <p>
              入学手続は、入学手続案内書で詳細を通知しますが、入学手続締切日までに学費を納付することで入学手続を完了します。入学手続締切日までに納付できない場合には、お問い合わせください。
              <br>1年分の一括払いや、事情のある方については、お問い合わせください。
              <br>3月31日までに入学辞退の申し出があった場合、入学金及び検定料以外の校納金を返還いたします。
            </p>
          </section>
        </section>
      </intersection-area>
    </section>
  </div>
  <script>
    export default {
      state: {
        currentTab: 'expenses',
        disableIntersection: false
      },
      tabs: [
        {
          name: '学費',
          slug: 'expenses'
        },
        {
          name: '指定校制学校推薦型選抜',
          slug: 'recommended-from-designated'
        },
        {
          name: '公募制学校推薦選抜',
          slug: 'recommended'
        },
        {
          name: '一般選抜',
          slug: 'general'
        },
        {
          name: '推薦・一般選抜日程',
          slug: 'schedule'
        },
        {
          name: '総合型選抜',
          slug: 'total'
        },
        {
          name: '各種選抜共通事項',
          slug: 'common'
        },
      ],
      clickTabHandler(slug) {
        this.state.disableIntersection = true
        this.state.currentTab = slug
        this.update()
        window.requestAnimationFrame(() => {
          window.location.href = `#${slug}`
        })
      },
      intersectionHandler(slug) {
        this.state.currentTab = slug
        this.update()
      },
      onMounted() {
        document.documentElement.style.setProperty('--mobile-scroll-offset-tab', `50px`)
        this.scrollToHashedElement()
      },
      onUpdated() {
        if (this.state.disableIntersection) {
          if (this.timeoutId) window.clearTimeout(this.timeoutId)
          this.timeoutId = window.setTimeout(() => {
            this.update({ disableIntersection: false })
          }, 1000)
        }
      }
    }
  </script>
</page-atsugi-design-application>