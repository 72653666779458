<page-kanagawa-highschool>
  <school-header data-is-school="{ true }" data-slug="kanagawa" data-school-name="専門学校神奈川総合大学校"
    data-sub-school-slug="highschool" data-sub-school-cource="高等課程"></school-header>
  <template if="{ isHome() }">
    <div class="c-school-hero">
      <div class="c-school-hero__heading">
        <slide-in data-theme="highschool">
          <div
            class="c-school-hero__heading-text c-school-hero__heading-text--theme-highschool o-cancel-first-child-gap">
            <p>
              専修学校だからできる職業教育。
              <br>高校生活から、夢に向けて
              <br>スタートダッシュを切ろう。
            </p>
          </div>
        </slide-in>
      </div>
      <div class="c-site-hero-photo c-site-hero-photo--kanagawa-highschool">
        <photo-slider data-slides="{slides}" data-theme="highschool"></photo-slider>
      </div>
    </div>
    <div class="c-home-content">
      <wave-separator class="c-home-content__wave" data-slug="highschool"></wave-separator>
      <div class="o-layout o-layout--column">
        <div class="u-only-mobile">
          <slide-in data-theme="highschool">
            <div class="c-message-text c-message-text--theme-kanagawa-highschool">
              <p>
                <span class="o-layout o-layout--wrap o-layout--main-axis-center o-layout--gap-none">
                  <span>専修学校だから</span>
                  <span>できる</span>
                  <span>職業教育。</span>
                </span>
                <span class="o-layout o-layout--wrap o-layout--main-axis-center o-layout--gap-none">
                  <span>高校生活から、</span>
                  <span>夢に向けて</span>
                  <span>スタートダッシュ</span>
                  <span>を切ろう。</span>
                </span>
              </p>
            </div>
          </slide-in>
        </div>
        <div>
          <div class="o-container">
            <div class="o-spacer o-spacer--large-2 u-only-desktop"></div>
            <home-nav-items data-items="{ homeNavItems }" data-theme="highschool"></home-nav-items>
          </div>
        </div>

        <!-- 2023/4/22 八十田 add start -->
        <div class="wrap">
          <div class="float-left">
            <div class="youtube">
              <iframe width="300" height="225" src="https://www.youtube.com/embed/nMofmnoTtMw?controls=0"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
            </div>
          </div>
          <div class="float-right">
            <div class="youtube">
              <iframe width="300" height="225" src="https://www.youtube.com/embed/zUM4ahDAgZc?controls=0"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
            </div>
          </div>
        </div>
        <!-- 2023/4/22 八十田 add end -->

        <section>
          <div class="o-container">
            <ui-heading data-tag="h1" data-theme="highschool">お知らせ一覧</ui-heading>
            <div class="o-layout o-layout--column o-layout--cross-axis-center">
              <div>
                <news-list data-slug="highschool" data-news="{ news }"></news-list>
              </div>
              <div class="o-items-center">
                <ui-button data-href="./news" data-class="theme-highschool">一覧を見る</ui-button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div class="o-spacer o-spacer--large-2"></div>
  </template>

  <!-- 2023/4/22 八十田 add start -->
  <!-- 2023/5/15 八十田 del start
  <a href="https://docs.google.com/forms/d/1dHxk2WYxLuN-J3g61iMTSZbAUmy7qAhm7n3QrP145gk/edit?pli=1">
    <picture class="floating-banner-y">
      <source srcset="/img/floating-banner-kanasou-pc.png" media="(min-width: 640px)" />
      <img class="floating-banner-image" src="/img/floating-banner-kanasou-sp.png" alt="">
    </picture>
  </a>
       2023/5/15 八十田 del end-->
  <!-- 2023/4/22 八十田 add end -->

  <template if="{ !isHome() }">
    <school-content-template if="{ isPage('subject') }" data-title="学校紹介" data-slug="highschool"
      data-current="/kanagawa/highschool/subject" data-photo="/img/photo-5.webp" data-home-path="/kanagawa/highschool/">
      <page-kanagawa-highschool-subject></page-kanagawa-highschool-subject>
    </school-content-template>
    <school-content-template if="{ isPage('subject/industrial') }" data-title="工業技術<wbr>学科" data-slug="highschool"
      data-current="/kanagawa/highschool/subject/industrial"
      data-photo="/img/photo-kanagawa-highschool-subject-electricity.webp" data-home-path="/kanagawa/highschool/"
      data-breadcrumbs="{[{ name: '学校紹介',link: '../'}]}">
      <page-kanagawa-highschool-industrial></page-kanagawa-highschool-industrial>
    </school-content-template>
    <school-content-template if="{ isPage('subject/design') }" data-title="生活造形<wbr>学科" data-slug="highschool"
      data-current="/kanagawa/highschool/subject/design" data-photo="/img/photo-kanagawa-highschool-subject-design.webp"
      data-home-path="/kanagawa/highschool/" data-breadcrumbs="{[{ name: '学校紹介',link: '../'}]}">
      <page-kanagawa-highschool-design></page-kanagawa-highschool-design>
    </school-content-template>
    <school-content-template if="{ isPage('campus') }" data-title="スクール<wbr>ライフ" data-slug="highschool"
      data-current="/kanagawa/highschool/campus" data-photo="/img/photo-kanagawa-highschool-campus.webp"
      data-home-path="/kanagawa/highschool/">
      <page-kanagawa-highschool-campus></page-kanagawa-highschool-campus>
    </school-content-template>
    <school-content-template if="{ isPage('open-campus') }" data-title="体験入学" data-slug="highschool"
      data-current="/kanagawa/highschool/open-campus" data-photo="/img/photo-kanagawa-highschool-open-campus.webp"
      data-home-path="/kanagawa/highschool/">
      <page-kanagawa-highschool-open-campus></page-kanagawa-highschool-open-campus>
    </school-content-template>
    <school-content-template if="{ isPage('message') }" data-title="教師・先輩からのメッセージ" data-slug="highschool"
      data-current="/kanagawa/highschool/message" data-photo="/img/photo-6.webp" data-home-path="/kanagawa/highschool/">
      <page-kanagawa-highschool-message></page-kanagawa-highschool-message>
    </school-content-template>
    <school-content-template if="{ isPage('application') }" data-title="募集要項" data-slug="highschool"
      data-current="/kanagawa/highschool/application" data-photo="/img/photo-4.webp"
      data-home-path="/kanagawa/highschool/">
      <page-kanagawa-highschool-application></page-kanagawa-highschool-application>
    </school-content-template>
    <school-content-template if="{ isPage('qa') }" data-title="よくあるご質問" data-slug="highschool"
      data-current="/kanagawa/highschool/qa" data-photo="/img/photo-8.webp" data-home-path="/kanagawa/highschool/">
      <page-kanagawa-highschool-qa></page-kanagawa-highschool-qa>
    </school-content-template>
    <school-content-template if="{ isPage('news') }" data-title="お知らせ" data-slug="highschool"
      data-current="/kanagawa/highschool/news" data-photo="/img/highschool-news-title.svg"
      data-home-path="/kanagawa/highschool/">
      <page-kanagawa-highschool-news data-news="{news}"></page-kanagawa-highschool-news>
    </school-content-template>
  </template>
  <div class="o-spacer o-spacer--large-2"></div>
  <div class="o-layout o-layout--column">
    <section class="c-footer-nav-block">
      <div class="o-container">
        <highschool-nav data-where="footer"></highschool-nav>
      </div>
    </section>
    <section>
      <group-footer></group-footer>
    </section>
  </div>
  <kanagawa-highschool-menu></kanagawa-highschool-menu>
  <script>
    // @ts-check
    export default function PageKanagawaHighSchool() {
      return {
        /**
         * @typedef slide
         * @property {String} photo
         * @property {String} [position]
         */
        /**
         * @type {slide[]}
         */
        slides: [
          { photo: '/img/kanagawa-highschool-photo.webp', },
          { photo: '/img/kanagawa-highschool-photo-3.webp' },
          { photo: '/img/kanagawa-highschool-photo-4.webp' },
          { photo: '/img/kanagawa-highschool-photo-5.webp' },
        ],
        /**
         * @typedef {object} nav
         * @property {string} icon
         * @property {string} title
         * @property {string} href
         */
        /**
         * @type {nav[]}
         */
        homeNavItems: [
          {
            icon: 'study',
            title: '学校紹介',
            href: '/kanagawa/highschool/subject'
          },
          {
            icon: 'school',
            title: 'スクール<wbr>ライフ',
            href: '/kanagawa/highschool/campus'
          },
          {
            icon: 'message',
            title: '教師・<wbr>先輩からの<br>メッセージ',
            href: '/kanagawa/highschool/message'
          },
          {
            icon: 'compass',
            title: '体験入学',
            href: '/kanagawa/highschool/open-campus'
          },
          {
            icon: 'file',
            title: '募集要項',
            href: '/kanagawa/highschool/application'
          },
          {
            icon: 'questions',
            title: 'よくあるご質問',
            href: '/kanagawa/highschool/qa'
          },
        ],
        /**
         * @typedef {object} news
         * @property {string} title
         * @property {string} date
         * @property {string} slug
         * @property {string} content
         * @property {string} [photo]
         */
        /**
         * @type {news[]}
         */
        news: [
          {
            title: '冬季休業期間のお知らせ',
            date: '2023年12月26日',
            slug: 'notice-of-winter-holidays-2023',
            content: '<p>【資料請求について】<br>年内の資料発送は12月26日（火）にて終了になります。<br>年明けの発送は1月9日（火）からです。<br>【出願、体験入学等の各種お問合せについて】<br>12月27日（水）～ 1月8日（月）は冬季休業になります。<br>期間中、お電話等の受付もお休みさせて頂きます。</p><p>ご不便をおかけしますが、何卒ご了承ください。</p>',
            photo: '/img/news-suzuki-logo.svg',
          },
          {
            title: '豊友祭2023を開催します',
            date: '2023年10月14日',
            slug: 'fes-2023',
            content: '<p>今年も豊友祭（学園祭）行います。<br>楽しいイベントが盛り沢山！是非遊びに来てください！</p><ul><li>日程 2023/10/28（土）</li><li>時間 10:00〜15:00</li><li>会場 神奈川県厚木市恩名1-17-18</li></ul><p><a href="/img/2023-fes-poster.pdf" target="_blank"><img src="/img/fes-2023.webp" alt="豊友祭2023ポスター"></a></p>',
            photo: '/img/news-fes-2023.webp',
          },
          {
            title: '夏季休暇期間について',
            date: '2023年8月10日',
            slug: '2022-2023',
            content: '<p>8/11（金）～8/16（水）まで夏季休暇に伴い、頂きましたお問合せや資料請求に関しましては8/17（木）から順次ご対応させて頂きます。<br>皆様には大変ご迷惑お掛けいたしますが、ご理解賜りますよう宜しくお願い致します。</p>',
            photo: '/img/news-suzuki-logo.svg',
          },
          {
            title: '2次募集について',
            date: '2023年3月1日',
            slug: '2023-03-01',
            content: '<p>本学園では2次募集を行います。詳細につきましては要項にてご確認をお願い致します。</p><ul><li><a href="/img/20230301.pdf">2次募集についての詳細PDF</a></li></ul>',
            photo: '/img/news-suzuki-logo.svg',
          },
          {
            title: '年末年始の資料請求・お問合せについて',
            date: '2022年12月26日',
            slug: '2022-2023',
            content: '<p> <b>【資料請求について】</b> 年内の資料発送は12月26日（金）にて終了になります。<br> 年明けの発送は1月6日（金）からです。 </p> <p> <b>【出願、体験入学等の各種お問合せについて】</b> 12月27日（火）～ 1月5日（木）は年末年始は休業になります。<br> 期間中、お電話等の受付もお休みさせて頂きます。 </p> <p>ご不便をおかけしますが、何卒ご了承ください。</p>',
            photo: '/img/news-suzuki-logo.svg',
          },
          {
            title: '学校説明会を開催します',
            date: '2022年11月17日',
            slug: 'open-campus-2022',
            content: '<p>中学生、保護者、先生を対象にした学校説明会を下記の日程で開催致します。<br>ぜひ奮ってご参加ください。 </p><ul><li>日程：11/19(土)、11/26(土)、12/3(土)</li><li>時間：10:00 〜</li><li>内容：学校説明、受験について、施設見学、個別相談等</li></ul><p>皆さまに安心して参加していただけるよう、感染予防体制を整えております。</p><p><a href="/img/open-campass-highschool-2022.pdf"><img src="/img/open-campass-highschool-2022.png" alt=""></a></p>',
            photo: '/img/news-open-campass-highschool-2022.png',
          },
          {
            title: '11月5日開催のあつぎ鮎まつりに参加します',
            date: '2022年10月26日',
            slug: 'ayumatsuri-2022',
            content: '<p>延期されていた「第76回あつぎ鮎まつり」の開催日が11月5日に決定しました。<br>ファッションショーは下記の時間に開催致します。</p><p>日程：2022/11/5（土）<br>時間：10:30〜11:00<br>会場：厚木中央公園　特設ステージ</p><p>マドレーヌ販売、チョークアート体験、<br>カークラフト作成等のイベントもありますので<br>是非、遊びに来て下さい！</p><p><img src="/img/ayumatsuri-2022.png" alt="鈴木学園3校合同 和装アレンジファッションショー"></p>',
            photo: '/img/news-ayumatsuri-2022.png',
          },
          {
            title: '豊友祭2022を開催します',
            date: '2022年10月17日',
            slug: 'fes-2022',
            content: '<p>3年ぶりに豊友祭（学園祭）行います。<br>新型コロナ感染症拡大防止の為、ホールイベント、飲食の販売等は<br>小規模になりますが是非遊びに来てください！</p><p>日程 2022/10/29（土）<br>時間 10:00〜16:00<br>会場 神奈川県厚木市恩名1-17-18</p><p><a href="/img/2022-fes-poster.pdf" target="_blank"><img src="/img/fes-2022.png" alt="豊友祭 冷和4年10月29日（土）10:00〜16:00"></a></p>',
            photo: '/img/news-2022-fes.png',
          },
          {
            title: '鈴木学園三校合同ファッションショー',
            date: '2022年9月16日',
            slug: 'pv-fashion-show-2022',
            content: '<p>8/5(金)開催の「鈴木学園三校合同ファッションショー」<br>【和洋折衷】和装アレンジファッションショーの様子をYouTubeにアップしました！</p><br><div class="c-video"><iframe width="560" height="315" src="https://www.youtube.com/embed/nMofmnoTtMw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>',
            photo: '/img/pv-fashion-show-2022-highschool.webp',
          },
          {
            title: '第76回あつぎ鮎まつりに参加します',
            date: '2022年7月8日',
            slug: 'atsugiayumatsuri-2022',
            content: '<img src="/img/news-kanagawa-highschool-ayumatsuri-2022.webp">',
            photo: '/img/news-kanagawa-highschool-ayumatsuri-2022-thumb.webp',
          },
          {
            title: '神奈川総合大学校 高等課程のPV動画を公開しました。',
            date: '2022年7月2日',
            slug: 'pv-2',
            content: '<div class="c-video"><iframe width="560" height="315" src="https://www.youtube.com/embed/zUM4ahDAgZc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>',
            photo: '/img/news-kanagawa-highschool-pv-2.webp',
          },
          {
            title: '2022年度体験入学ついて',
            date: '2022年4月1日',
            slug: 'open-campus-2022',
            content: '2022年度体験入学予定を公開しています。  \n皆様ぜひご参加ください。\n\n- [体験入学ページ](/kanagawa/highschool/open-campus)',
            photo: '/img/photo-kanagawa-highschool-open-campus.webp',
          },
          {
            title: 'サイトリニューアルのお知らせ',
            date: '2022年2月28日',
            slug: 'site-renewal-2022',
            content: 'この度、Webサイトを全面リニューアルいたしました。',
            photo: '/img/news-renewal-highschool.png',
          },
          {
            title: '神奈川総合大学校 高等課程のPV動画を公開しました。',
            date: '2022年2月28日',
            slug: 'pv',
            content: '<video controls autoplay class="c-video"><source src="/media/pv-kanagawa-highschool.webm" type="video/webm">恐れ入りますが、お使いのブラウザーは動画再生に対応していないようです。</video>',
            photo: '/img/news-kanagawa-highschool-pv.webp',
          },
          {
            title: '2021年度体験入学ついて',
            date: '2021年4月1日',
            slug: 'open-campus-2021',
            content: '2021年度体験入学予定を公開しています。  \n皆様ぜひご参加ください。\n\n- [体験入学ページ](/kanagawa/highschool/open-campus)',
            photo: '/img/photo-kanagawa-highschool-open-campus.webp',
          },
          {
            title: '新型コロナウイルス感染予防について',
            date: '2021年4月1日',
            slug: 'covid-19',
            content: '- 定期的な消毒、換気、教職員のマスク着用など感染症対策に配慮しながら実施しています。\n- ご来校時に検温をお願いしています。熱がある場合は恐れ入りますが、参加をご遠慮頂いております。\n- 新型コロナウイルス感染拡大防止の為、予定が変更になる場合がございます。',
            photo: '/img/news-covid-19.webp',
          },
        ],
        /**
         * @type {string[]}
         */
        pathArray: [],
        /**
         * @return {boolean}
         */
        isHome() {
          return this.pathArray.length === 2
        },
        /**
         * @param {string} slug
         * @return {boolean}
         */
        isPage(slug) {
          const pathname = window.location.pathname.match(/^\/kanagawa\/highschool\/(.*)/)[1].replace(/(.*)\/$/, '$1')
          return pathname === slug
        },
        onBeforeMount() {
          this.pathArray = this.getPathNameArray()
        },
        onMounted() {
          this.scrollToHashedElement()
        }
      }
    }
  </script>
</page-kanagawa-highschool>