<page-suzuki-contact>
  <div hidden="{state.pageStateSlug != 'school-select'}" aria-hidden="{state.pageStateSlug != 'school-select'}">
    <section>
      <h1 class="c-heading-4">ご希望の学校・学科をお選びください</h1>
      <div class="o-spacer"></div>
      <div class="o-items-center">
        <div class="o-layout o-layout--column o-layout--gap-small">
          <div each="{school in state.schoolList}">
            <label onclick="{setSchoolSlug.bind(this, school.slug)}">
              <div class="o-layout">
                <div>
                  <input type="radio" name="school" value="{school.slug}">
                </div>
                <div>
                  <span>{school.title}</span>
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
    </section>
    <div class="o-items-center">
      <p>
        <ui-button data-disabled="{!state.schoolSlug}" onclick="{goToForm}">お問い合わせフォームを開く
        </ui-button>
      </p>
      <p if="{!state.schoolSlug}">
        <small>まず上記よりご希望の資料を選択してください。</small>
      </p>
    </div>
  </div>
  <template if="{state.pageStateSlug === 'form'}">
    <p>
      <ui-button onclick="{setPageStateSlug.bind(this,'school-select')}">
        学校・学科選択に戻る
      </ui-button>
    </p>
    <div class="o-spacer"></div>
    <form action="">
      <table class="c-table">
        <tr>
          <th>
            <div class="o-layout">
              <div class="o-size-fill">お名前</div>
              <div>
                <span class="c-label">必須</span>
              </div>
            </div>
          </th>
          <td class="o-fit">
            <template if="{state.mode === 'confirm'}">
              {state.dataSubmit['名前']}
            </template>
            <input hidden="{state.mode === 'confirm'}" data-ref-input="名前" type="text" class="c-input-text">
          </td>
        </tr>
        <tr>
          <th>
            <div class="o-layout">
              <div class="o-size-fill">フリガナ</div>
              <div>
                <span class="c-label">必須</span>
              </div>
            </div>
          </th>
          <td class="o-fit">
            <template if="{state.mode === 'confirm'}">
              {state.dataSubmit['フリガナ']}
            </template>
            <input type="text" hidden="{state.mode === 'confirm'}" data-ref-input="フリガナ" class="c-input-text">
          </td>
        </tr>
        <tr>
          <th>
            <div class="o-layout">
              <div class="o-size-fill">メールアドレス</div>
              <div>
                <span class="c-label">必須</span>
              </div>
            </div>
          </th>
          <td class="o-fit">
            <template if="{state.mode === 'confirm'}">
              {state.dataSubmit['メールアドレス']}
            </template>
            <input hidden="{state.mode === 'confirm'}" data-ref-input="メールアドレス" type="email" class="c-input-text">
          </td>
        </tr>
        <tr>
          <th>
            <div class="o-layout">
              <div class="o-size-fill">電話番号</div>
              <div>
                <span class="c-label">必須</span>
              </div>
            </div>
          </th>
          <td class="o-fit">
            <template if="{state.mode === 'confirm'}">
              {state.dataSubmit['電話番号']}
            </template>
            <input hidden="{state.mode === 'confirm'}" data-ref-input="電話番号" type="tel" class="c-input-text">
          </td>
        </tr>
        <tr>
          <th>
            <div class="o-layout">
              <div class="o-size-fill">問い合わせ先の学校名</div>
            </div>
          </th>
          <td>
            {getSelectedSchoolName()}
          </td>
        </tr>
        <tr>
          <th>お問い合わせ内容</th>
          <td class="o-fit">
            <template if="{state.mode === 'confirm'}">
              <raw-html data-content="{breakToBr(state.dataSubmit['お問い合わせ内容'])}"></raw-html>
            </template>
            <textarea hidden="{state.mode === 'confirm'}" data-ref-input="お問い合わせ内容" class="c-textarea" cols="30"
              rows="5"></textarea>
          </td>
        </tr>
      </table>
      <div class="o-spacer"></div>
      <div class="o-layout o-layout--main-axis-center">
        <div if="{state.mode === 'input'}">
          <ui-button data-tag="button" onclick="{clickConform}">入力内容確認</ui-button>
        </div>
        <template if="{state.mode === 'confirm'}">
          <div>
            <ui-button data-tag="button" onclick="{backToInput}">修正する</ui-button>
          </div>
          <div>
            <ui-button data-tag="button" onclick="{clickSubmit}" data-button-type="submit">この内容で送信する</ui-button>
          </div>
        </template>
      </div>
    </form>
  </template>
  <template if="{state.pageStateSlug === 'thanks'}">
    <section>
      <h1 class="c-heading-4">お問い合わせ頂きありがとうございます。</h1>
      <p>お送り頂いた内容を確認した後、弊社担当者より折り返しご連絡させて頂きますので、今しばらくおまちください。</p>
      <div class="o-spacer"></div>
      <div class="o-layout o-layout--main-axis-center">
        <div>
          <ui-button data-href="./">問い合わせに戻る</ui-button>
        </div>
        <div>
          <ui-button data-href="/">鈴木学園トップに戻る</ui-button>
        </div>
      </div>
    </section>
  </template>
  <script>
    export default {
      state: {
        /**
         * @type {('input'|'confirm'|'thanks')} mode
         */
        mode: 'input',
        /**
         * @type {Object} formData
         */
        formData: {
          '名前': { require: true, value: '' },
          'フリガナ': { require: true, value: '' },
          'メールアドレス': { require: true, value: '' },
          '電話番号': { require: false, value: '' },
          'お問い合わせ内容': { require: false, value: '' },
        },
        /**
         * フォームに送信するデータ
         */
        dataSubmit: {},
        /**
         * @type {string|null}
         */
        schoolSlug: null,
        /**
         * @type {('school-select'|'form'|'thanks')}
         */
        pageStateSlug: 'school-select',
        schoolList: [
          {
            title: '厚木中央高等学校',
            slug: 'chuo'
          },
          {
            title: '神奈川総合大学校 高等課程',
            slug: 'highschool'
          },
          {
            title: '神奈川総合大学校 専門課程 自動車整備科',
            slug: 'car'
          },
          {
            title: '厚木総合専門学校 調理学科',
            slug: 'cook'
          },
          {
            title: '厚木総合専門学校 生活デザイン学科',
            slug: 'design'
          },
          {
            title: '厚木総合専門学校 歯科衛生学科',
            slug: 'dental'
          },
        ]
      },
      /**
       * @return {Boolean}
       */
      getIsInlineForm() {
        const result = !this.state.schoolSlug.match(/(cook|design|dental)/)
        return result
      },
      goToForm() {
        if (this.getIsInlineForm()) {
          this.setPageStateSlug('form')
        }
        else {
          if (this.state.schoolSlug === 'cook') {
            window.open('https://www.school-go.info/f9z653/atsugi/form.php?fno=104&fsno=1&openExternalBrowser=1', '_blank')
          }
          else if (this.state.schoolSlug === 'design') {
            window.open('https://www.school-go.info/f9z653/atsugi/form.php?fno=97&fsno=1&openExternalBrowser=1', '_blank')
          }
          else if (this.state.schoolSlug === 'dental') {
            window.open('https://www.school-go.info/f9z653/atsugi/form.php?fno=90&fsno=1&openExternalBrowser=1', '_blank')
          }
        }
      },
      /**
       * @param {String} slug
       */
      setSchoolSlug(slug) {
        this.state.schoolSlug = slug
        this.update()
      },
      /**
       * @param {String} slug
       */
      setPageStateSlug(slug) {
        this.state.pageStateSlug = slug
        this.update()
        window.scrollTo(0, 0)
      },
      getSelectedSchoolName() {
        /**
         * @type {string}
         */
        const result = this.state.schoolList.filter(school => {
          return school.slug === this.state.schoolSlug
        })[0].title
        // console.log(result)
        return result
      },
      clickConform(event) {
        event.preventDefault()
        this.setFormData()
      },
      resetData() {
        this.state.formData = this.state.formDataDefault
        this.state.dataSubmit = {}
      },
      setFormData() {
        this.resetData()
        Object.keys(this.state.formData).forEach(key => {
          console.log(key)
          this.state.formData[key].value = this.$(`[data-ref-input="${key}"]`).value
          this.state.dataSubmit[key] = this.state.formData[key].value
        })
        this.state.dataSubmit['問い合わせ先の学校名'] = this.getSelectedSchoolName()
        this.state.dataSubmit['管理先メールアドレス'] = this.dataset.emails[this.state.schoolSlug]
        this.checkRequiredValue()
      },
      checkRequiredValue() {
        const result = Object.keys(this.state.formData).some(key => {
          return !this.state.formData[key].value && this.state.formData[key].require
        })
        if (result) {
          alert('恐れ入りますが入力に不足があるようです。\n「必須」と表示された入力欄に正しく記入されたかご確認ください。')
        }
        else {
          this.goToConfirm()
        }
      },
      goToConfirm() {
        this.state.mode = 'confirm'
        this.update()
        window.scrollTo(0, 0)
      },
      backToInput() {
        this.state.mode = 'input'
        this.update()
        window.scrollTo(0, 0)
      },
      clickSubmit(event) {
        event.preventDefault()
        this.submitForm()
      },
      submitForm() {
        if (!this.state.submitted) {
          this.state.submitted = true
          fetch('/cockpit/api/forms/submit/contact', {
            method: 'post',
            credentials: "same-origin",
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer 35caad65cd1c9add5bdeb758fd62a0',
            },
            body: JSON.stringify({
              form: this.state.dataSubmit
            })
          })
            .then(result => result.json())
            .then(result => {
              console.log(result)
              this.state.submitted = false
              this.goToThanks()
            })
            .catch(error => {
              console.log(error)
              alert('申し訳ありませんが、送信時に予期せぬエラーが発生しました。\nしばらく経ってから再度お試しください。')
              this.state.submitted = false
            })
        }
      },
      goToThanks() {
        this.state.pageStateSlug = 'thanks'
        this.update()
        window.scrollTo(0, 0)
      },
      backupDefaultData() {
        this.state.formDataDefault = JSON.parse(JSON.stringify(this.state.formData))
      },
      onBeforeMount() {
        this.backupDefaultData()
      },
      onMounted() {
        this.scrollToHashedElement()
      }
    }
  </script>
</page-suzuki-contact>