<page-atsugi-cook>
  <school-header data-is-school="{ true }" data-slug="atsugi" data-school-name="厚木総合専門学校" data-sub-school-slug="cook"
    data-sub-school-cource="調理学科"></school-header>
  <template if="{ isHome() }">
    <div class="c-school-hero">
      <div class="c-school-hero__heading">
        <slide-in data-theme="cook">
          <div class="c-school-hero__heading-text c-school-hero__heading-text--theme-cook o-cancel-first-child-gap">
            <p class="o-word-wrap-control">
              中学卒業から、<wbr>すべての大人の方まで。
              <br>調理経験<wbr>のない方<wbr>でも<wbr>安心して<wbr>学べます。
            </p>
          </div>
        </slide-in>
      </div>
      <div class="c-site-hero-photo c-site-hero-photo--atsugi-cook">
        <photo-slider data-slides="{slides}" data-theme="cook"></photo-slider>
      </div>
    </div>
    <div class="c-home-content">
      <wave-separator class="c-home-content__wave" data-slug="cook"></wave-separator>
      <div class="o-layout o-layout--column">
        <div class="u-only-mobile">
          <slide-in data-theme="cook">
            <div class="c-message-text c-message-text--theme-atsugi-cook">
              <p>
                <span class="o-layout o-layout--wrap o-layout--gap-none o-layout--main-axis-center">
                  <span>中学卒業から、</span>
                  <span>すべての大人の方まで。</span>
                </span>
                <span class="o-layout o-layout--wrap o-layout--gap-none o-layout--main-axis-center">
                  <span>調理経験</span>
                  <span>のない方</span>
                  <span>でも</span>
                  <span>安心して</span>
                  <span>学べます。</span>
                </span>
              </p>
            </div>
          </slide-in>
        </div>
        <div>
          <div class="o-container">
            <div class="o-spacer o-spacer--large-2 u-only-desktop"></div>
            <home-nav-items data-items="{ homeNavItems }" data-theme="cook"></home-nav-items>
          </div>
        </div>
        <section>
          <div class="o-container">
            <ui-heading data-tag="h1" data-theme="cook">お知らせ一覧</ui-heading>
            <div class="o-layout o-layout--column o-layout--cross-axis-center">
              <div>
                <news-list data-slug="cook" data-news="{ news }"></news-list>
              </div>
              <div class="o-items-center">
                <ui-button data-href="./news" data-class="theme-cook">一覧を見る</ui-button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div class="o-spacer o-spacer--large-2"></div>
  </template>
  <template if="{ !isHome() }">
    <school-content-template if="{ isPage('subject') }" data-title="コース・教科・取得資格" data-slug="cook"
      data-current="/atsugi/cook/subject" data-photo="/img/photo-atsugi-cook-subject.webp">
      <page-atsugi-cook-subject></page-atsugi-cook-subject>
    </school-content-template>
    <school-content-template if="{ isPage('campus') }" data-title="キャンパスライフ" data-slug="cook"
      data-current="/atsugi/cook/campus" data-photo="/img/photo-atsugi-cook-campus.webp">
      <page-atsugi-cook-campus></page-atsugi-cook-campus>
    </school-content-template>
    <school-content-template if="{ isPage('open-campus') }" data-title="オープン<wbr>キャンパス" data-slug="cook"
      data-current="/atsugi/cook/open-campus" data-photo="/img/photo-atsugi-cook-open-campus.webp">
      <page-atsugi-cook-open-campus></page-atsugi-cook-open-campus>
    </school-content-template>
    <school-content-template if="{ isPage('message') }" data-title="教師・先輩からのメッセージ" data-slug="cook"
      data-current="/atsugi/cook/message" data-photo="/img/photo-atsugi-cook-message.webp">
      <page-atsugi-cook-message></page-atsugi-cook-message>
    </school-content-template>
    <school-content-template if="{ isPage('application') }" data-title="募集要項" data-slug="cook"
      data-current="/atsugi/cook/application" data-photo="/img/photo-4.webp">
      <page-atsugi-cook-application></page-atsugi-cook-application>
    </school-content-template>
    <school-content-template if="{ isPage('qa') }" data-title="よくあるご質問" data-slug="cook" data-current="/atsugi/cook/qa"
      data-photo="/img/photo-atsugi-cook-qa.webp">
      <page-atsugi-cook-qa></page-atsugi-cook-qa>
    </school-content-template>
    <school-content-template if="{ isPage('news') }" data-title="お知らせ" data-slug="cook" data-current="/atsugi/cook/news"
      data-photo="/img/cook-news-title.svg">
      <page-atsugi-cook-news data-news="{news}"></page-atsugi-cook-news>
    </school-content-template>

  </template>
  <div class="o-spacer o-spacer--large-2"></div>
  <div class="o-layout o-layout--column">
    <section class="c-footer-nav-block">
      <div class="o-container">
        <cook-nav data-where="footer"></cook-nav>
      </div>
    </section>
    <section>
      <group-footer></group-footer>
    </section>
  </div>
  <atsugi-cook-menu></atsugi-cook-menu>
  <script>
    // @ts-check
    export default function PageAtsugiCook() {
      return {
        /**
         * @typedef slide
         * @property {String} photo
         * @property {String} [position]
         */
        /**
         * @type {slide[]}
         */
        slides: [
          { photo: '/img/atsugi-cook-photo.webp', },
          {
            photo: '/img/atsugi-cook-photo-2.webp',
            position: '100% 50%',
          },
          { photo: '/img/atsugi-cook-photo-3.webp', },
        ],
        /**
         * @typedef {object} nav
         * @property {string} icon
         * @property {string} title
         * @property {string} href
         */
        /**
         * @type {nav[]}
         */
        homeNavItems: [
          {
            icon: 'study',
            title: 'コース・<wbr>教科・<wbr>資格検定',
            href: '/atsugi/cook/subject'
          },
          {
            icon: 'school',
            title: 'キャンパス<wbr>ライフ',
            href: '/atsugi/cook/campus'
          },
          {
            icon: 'message',
            title: '教師・<wbr>先輩からの<wbr>メッセージ',
            href: '/atsugi/cook/message'
          },
          {
            icon: 'compass',
            title: 'オープン<wbr>キャンパス',
            href: '/atsugi/cook/open-campus'
          },
          {
            icon: 'file',
            title: '募集要項',
            href: '/atsugi/cook/application'
          },
          {
            icon: 'questions',
            title: 'よくあるご質問',
            href: '/atsugi/cook/qa'
          },

        ],
        /**
         * @typedef {object} news
         * @property {string} title
         * @property {string} date
         * @property {string} slug
         * @property {string} content
         * @property {string} [photo]
         */
        /**
         * @type {news[]}
         */
        news: [
          {
            title: '冬季休業期間のお知らせ',
            date: '2023年12月26日',
            slug: 'notice-of-winter-holidays-2023',
            content: '<p>【資料請求について】<br>年内の資料発送は12月26日（火）にて終了になります。<br>年明けの発送は1月9日（火）からです。<br>【出願、体験入学等の各種お問合せについて】<br>12月27日（水）～ 1月8日（月）は冬季休業になります。<br>期間中、お電話等の受付もお休みさせて頂きます。</p><p>ご不便をおかけしますが、何卒ご了承ください。</p>',
            photo: '/img/news-suzuki-logo.svg',
          },
          {
            title: '豊友祭2023を開催します',
            date: '2023年10月14日',
            slug: 'fes-2023',
            content: '<p>今年も豊友祭（学園祭）行います。<br>楽しいイベントが盛り沢山！是非遊びに来てください！</p><ul><li>日程 2023/10/28（土）</li><li>時間 10:00〜15:00</li><li>会場 神奈川県厚木市恩名1-17-18</li></ul><p><a href="/img/2023-fes-poster.pdf" target="_blank"><img src="/img/fes-2023.webp" alt="豊友祭2023ポスター"></a></p>',
            photo: '/img/news-fes-2023.webp',
          },
          {
            title: '夏季休暇期間について',
            date: '2023年8月10日',
            slug: '2022-2023',
            content: '<p>8/11（金）～8/16（水）まで夏季休暇に伴い、頂きましたお問合せや資料請求に関しましては8/17（木）から順次ご対応させて頂きます。<br>皆様には大変ご迷惑お掛けいたしますが、ご理解賜りますよう宜しくお願い致します。</p>',
            photo: '/img/news-suzuki-logo.svg',
          },
          {
            title: '2次募集について',
            date: '2023年3月1日',
            slug: '2023-03-01',
            content: '<p>本学園では2次募集を行います。詳細につきましては要項にてご確認をお願い致します。</p><ul><li><a href="/img/20230301.pdf">2次募集についての詳細PDF</a></li></ul>',
            photo: '/img/news-suzuki-logo.svg',
          },
          {
            title: '年末年始の資料請求・お問合せについて',
            date: '2022年12月26日',
            slug: '2022-2023',
            content: '<p> <b>【資料請求について】</b> 年内の資料発送は12月26日（金）にて終了になります。<br> 年明けの発送は1月6日（金）からです。 </p> <p> <b>【出願、体験入学等の各種お問合せについて】</b> 12月27日（火）～ 1月5日（木）は年末年始は休業になります。<br> 期間中、お電話等の受付もお休みさせて頂きます。 </p> <p>ご不便をおかけしますが、何卒ご了承ください。</p>',
            photo: '/img/news-suzuki-logo.svg',
          },
          {
            title: '学校説明会を開催します',
            date: '2022年11月17日',
            slug: 'open-campus-2022',
            content: '<p>中学生、保護者、先生を対象にした学校説明会を下記の日程で開催致します。<br>ぜひ奮ってご参加ください。 </p><ul><li>日程：11/19(土)、11/26(土)、12/3(土)</li><li>時間：10:00 〜</li><li>内容：学校説明、受験について、施設見学、個別相談等</li></ul><p>皆さまに安心して参加していただけるよう、感染予防体制を整えております。</p><p><a href="/img/open-campass-cook-2022.pdf"><img src="/img/open-campass-cook-2022.png" alt=""></a></p>',
            photo: '/img/news-open-campass-cook-2022.png',
          },
          {
            title: '11月5日開催のあつぎ鮎まつりに参加します',
            date: '2022年10月26日',
            slug: 'ayumatsuri-2022',
            content: '<p>延期されていた「第76回あつぎ鮎まつり」の開催日が11月5日に決定しました。<br>ファッションショーは下記の時間に開催致します。</p><p>日程：2022/11/5（土）<br>時間：10:30〜11:00<br>会場：厚木中央公園　特設ステージ</p><p>マドレーヌ販売、チョークアート体験、<br>カークラフト作成等のイベントもありますので<br>是非、遊びに来て下さい！</p><p><img src="/img/ayumatsuri-2022.png" alt="鈴木学園3校合同 和装アレンジファッションショー"></p>',
            photo: '/img/news-ayumatsuri-2022.png',
          },
          {
            title: '豊友祭2022を開催します',
            date: '2022年10月17日',
            slug: 'fes-2022',
            content: '<p>3年ぶりに豊友祭（学園祭）行います。<br>新型コロナ感染症拡大防止の為、ホールイベント、飲食の販売等は<br>小規模になりますが是非遊びに来てください！</p><p>日程 2022/10/29（土）<br>時間 10:00〜16:00<br>会場 神奈川県厚木市恩名1-17-18</p><p><a href="/img/2022-fes-poster.pdf" target="_blank"><img src="/img/fes-2022.png" alt="豊友祭 冷和4年10月29日（土）10:00〜16:00"></a></p>',
            photo: '/img/news-2022-fes.png',
          },
          {
            title: '第76回あつぎ鮎まつりに参加します',
            date: '2022年7月8日',
            slug: 'atsugiayumatsuri-2022',
            content: '<img src="/img/news-atsugi-cook-ayumatsuri-2022.webp">',
            photo: '/img/news-atsugi-cook-ayumatsuri-2022-thumb.webp',
          },
          {
            title: '2022年度オープンキャンパスについて',
            date: '2022年4月1日',
            slug: 'open-campus-2022',
            content: '2022年度オープンキャンパス実施予定を公開しています。  \n皆様ぜひご参加ください。\n\n- [オープンキャンパスページ](/atsugi/cook/open-campus)',
            photo: '/img/photo-atsugi-cook-open-campus.webp',
          },
          {
            title: 'サイトリニューアルのお知らせ',
            date: '2022年2月28日',
            slug: 'site-renewal-2022',
            content: 'この度、Webサイトを全面リニューアルいたしました。',
            photo: '/img/news-renewal-cook.png',
          },
          {
            title: '新型コロナウイルス感染予防について',
            date: '2022年2月28日',
            slug: 'covid-19',
            content: '- 定期的な消毒、換気、教職員のマスク着用など感染症対策に配慮しながら実施しています。\n- ご来校時に検温をお願いしています。熱がある場合は恐れ入りますが、参加をご遠慮頂いております。\n- 新型コロナウイルス感染拡大防止の為、予定が変更になる場合がございます。',
            photo: '/img/news-covid-19.webp',
          },
          {
            title: '2021年度オープンキャンパスについて',
            date: '2021年4月1日',
            slug: 'open-campus-2021',
            content: '2021年度オープンキャンパス実施予定を公開しています。  \n皆様ぜひご参加ください。\n\n- [オープンキャンパスページ](/atsugi/cook/open-campus)',
            photo: '/img/photo-atsugi-cook-open-campus.webp',
          },
        ],
        /**
         * @type {string[]}
         */
        pathArray: [],
        /**
         * @return {boolean}
         */
        isHome() {
          return this.pathArray.length === 2
        },
        /**
         * @param {string} slug
         * @return {boolean}
         */
        isPage(slug) {
          const pathname = window.location.pathname.match(/^\/atsugi\/cook\/(.*)/)[1].replace(/(.*)\/$/, '$1')
          return pathname === slug
        },
        onBeforeMount() {
          this.pathArray = this.getPathNameArray()
        },
        onMounted() {
          this.scrollToHashedElement()
        }
      }
    }
  </script>
</page-atsugi-cook>